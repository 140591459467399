import { $api, assetApi, $assets, agentApi, ucApi } from '@/config/api';

import { isClient } from '@vueuse/shared';
import { useUserStore } from './User';
import _ from 'lodash';
import { useConfigStore } from './config';
import { backoff } from '@/utils/backoff';

type titleItem = {
  content?: string
  color?: string
  br?: boolean
};

type disclaimerItem = {
  disclaimer: string
  disclaimerLinkText: string
  disclaimerLinkUrl: string
};

type bannerConfig = {
  eventKey?: string
  imageUrl: string
  topLeftCorner?: string
  title?: Array<titleItem>
  subtitle?: Array<titleItem>
  linkUrl?: string
  disclaimer?: disclaimerItem
};

export interface DeviceInfo {
  channel: string; // 渠道
  deviceNo: string; // 设备号,
  ip: string; // ip地址,
  zone: string; // 地区,
  countryCode: string; // 国家代码,
  region: string; // 省份,
  city: string; // 城市,
  vpn: boolean; // 是否是vpn,
  fraudScoreConfig: number; // 作弊分数配置,
  fraudScore: number; // 作弊分数,
  fromSource: string; // 来源,
  crawler: boolean; // 是否是爬虫,
  whiteIp: boolean; // 是否是白名单ip,
  robot: boolean; // 是否是机器人,
}

export const useCommonStore = defineStore('commonStore', {
  state: () => ({
    isMobile: <any>false,
    lang: isClient ? (localStorage.getItem('lang') || 'en-US') : 'en-US',
    isDark: isClient ? (localStorage.getItem('isDark') || 'dark') : 'dark',
    currencys: <Array<Common.Token>>[], // 币列表
    // currencyMap: <any>{}, // 币map
    symbols: <Array<Common.SpotSymbol>>[], // 交易对列表
    // symbolMap: {}, // 交易对map
    config: null, // 币，币对，币选项
    // 汇率时间控制
    rateTime: 0,
    // 汇率
    rates: <Array<Record<string, any>>>[],
    suffix: '', // 当前的折合汇率
    prefix: '', // 当前折合币种符号
    // 热门交易对
    hotSymbols: <Record<string, any>>{},
    isWithdrawal: false,
    isLoading: false,
    fingerPrinted: false,
    // 1创新区， 2观察区
    regionType: 0,
    homeBanner: {} as bannerConfig,
    // 管辖区提示
    isIpWarning: <boolean>false,
    // 设备信息
    deviceInfo: {} as DeviceInfo
  }),
  getters: {
    $currencys(state) {
      return state.currencys;
    },
    $symbols(state) {
      return state.symbols;
    },
    getSuffix(state): string {
      return state.suffix;
    },
    /**
     * 币种对应的法币汇率
     *
     * 数据格式： {tokenId: rates<legal, value>}
     * @returns object
     */
    ratesMap(state) {
      const { rates } = state;

      const rateMap: Record<string, object> = {};
      rates.forEach((item: Record<string, any>) => {
        rateMap[item.token] = item.rates;
      });

      return rateMap;
    },
    symbolMap(state) {
      const obj: any = {};

      if (!state.symbols || !state.symbols.length) {
        return obj;
      }

      state.symbols.forEach((item: any) => {
        obj[item.symbolId] = item;
      });

      return obj;
    },
    currencyMap(state) {
      const obj: Record<string, Common.Token> = {};

      if (!state.currencys || !state.currencys.length) {
        return obj;
      }

      state.currencys.forEach((item: any) => {
        obj[item.tokenId as string] = item;
      });

      return obj;
    },
    getFingerPrinted(state) {
      return state.fingerPrinted;
    },
    // 美国IP
    isUs(state) {
      return state.deviceInfo?.countryCode === 'US';
    }
  },
  actions: {
    changeRegionType(type: any) {
      this.regionType = type;
    },
    changeIpWarning(val: boolean) {
      this.isIpWarning = val;
    },
    changeDeviceInfo(val: DeviceInfo) {
      this.deviceInfo = val;
    },
    // 设备信息
    getDeviceInfo() {
      if (JSON.stringify(this.deviceInfo) != '{}') {
        return;
      }
      // 设备信息
      ucApi.getDeviceInfo().then((data: any) => {
        if (data.code == 200) {
          this.deviceInfo = data.data;
        }
      });
    },
    // 获取所有币种
    async getCurrency() {
      // if (this.currencys && this.currencys.length) {
      //   return;
      // }
      const res: any = await backoff($api.getCurrency);

      if (res.success && res.data) {
        /**
         * 用户权限 > token权限
         * @param userPermissions 用户所有权限 对象类型
         * @param tokenPermissions 当前token权限 boolean
         * @param tokenType token类型
         * @param actionType deposit 或 withdraw
         * @param isEffect 是否生效
         * @returns boolean
        */
        // 国际站去掉法币
        res.data = res.data?.filter((item: any) => item?.tokenType !== 'REAL_MONEY');

        const getWithdrawDepositPermissions = (tokenType: string, actionType: string, userPermissions: any, tokenPermissions: boolean, isEffect: boolean) => {

          let user = tokenPermissions;
          const token: boolean = tokenPermissions;

          if (actionType === 'deposit') {
            if (tokenType == 'REAL_MONEY') {
              user = userPermissions.allowFiatDeposit;
            } else if (tokenType == 'SECURITY_TOKEN') {
              user = userPermissions.allowSTTokenDeposit;
            } else {
              user = userPermissions.allowTokenDeposit;
            }
          } else if (actionType === 'withdraw') {
            if (tokenType == 'REAL_MONEY') {
              user = userPermissions.allowFiatWithdraw;
            } else if (tokenType == 'SECURITY_TOKEN') {
              user = userPermissions.allowSTTokenWithdraw;
            } else {
              user = userPermissions.allowTokenWithdraw;
            }
          }

          // 用户权限(user) > 币种权限(token)
          if (user) {
            if (isEffect) {
              return token;
            } else {
              return false;
            }
          } else {
            return user;
          }
        };

        /**
         * 用户权限 > token权限
         * @param userPermissions 用户权限
         * @param tokenPermissions token权限
         * @param isEffect token权限是否生效
         * @returns boolean
         */
        const getTransferPermissions = (userPermissions: boolean, tokenPermissions: boolean, isEffect: boolean) => {
          if (userPermissions) {
            if (isEffect) {
              return tokenPermissions;
            } else {
              return false;
            }
          } else {
            return userPermissions;
          }
        };
        const userStore = useUserStore();
        const getPermissions = (data: any) => {

          const userInfo = userStore.userinfo;
          const { riskSettings, userType } = userInfo;

          // 用户权限
          const userPermissions = {
            allowFiatDeposit: riskSettings ? !riskSettings?.disallowFiatDeposit : true,
            allowFiatWithdraw: riskSettings ? !riskSettings?.disallowFiatWithdraw : true,
            allowSTTokenDeposit: riskSettings ? !riskSettings?.disallowSTTokenDeposit : true,
            allowSTTokenWithdraw: riskSettings ? !riskSettings?.disallowSTTokenWithdraw : true,
            allowTokenDeposit: riskSettings ? !riskSettings?.disallowTokenDeposit : true,
            allowTokenWithdraw: riskSettings ? !riskSettings?.disallowTokenWithdraw : true,
            allowTransferCustodyToTrade: riskSettings ? !riskSettings?.disallowTransferCustodyToTrade : true,
            allowTransferFiatToTrade: riskSettings ? !riskSettings?.disallowTransferFiatToTrade : true,
            allowTransferTradeToCustody: riskSettings ? !riskSettings?.disallowTransferTradeToCustody : true,
            allowTransferTradeToFiat: riskSettings ? !riskSettings?.disallowTransferTradeToFiat : true,
            allowTransferTradeToTrade: riskSettings ? !riskSettings?.disallowTransferTradeToTrade : true,
            allowOptTransferOut: riskSettings ? !riskSettings?.disallowOptTransferOut : true,
            allowTransferTradeToFuture: riskSettings ? !riskSettings?.disallowTransferTradeToFuture : true,
            allowTransferFutureToTrade: riskSettings ? !riskSettings?.disallowTransferFutureToTrade : true,
            allowTransferCustodyToFuture: riskSettings ? !riskSettings?.disallowTransferCustodyToFuture : true,
            allowTransferFutureToCustody: riskSettings ? !riskSettings?.disallowTransferFutureToCustody : true
          };

          return data?.map((x: any) => {
            const { riskSettings, allowDeposit, allowWithdraw } = x;
            const { supportUserTypes } = riskSettings;
            const isEffect = supportUserTypes ? supportUserTypes?.find((x: any) => x == userType) : true;

            // 当前token的权限
            const tokenPermissions = {
              allowTransferCustodyToTrade: !riskSettings?.disallowTransferCustodyToTrade,
              allowTransferFiatToTrade: !riskSettings?.disallowTransferFiatToTrade,
              allowTransferTradeToCustody: !riskSettings?.disallowTransferTradeToCustody,
              allowTransferTradeToFiat: !riskSettings?.disallowTransferTradeToFiat,
              allowTransferTradeToFuture: !riskSettings?.disallowTransferTradeToFuture,
              allowTransferFutureToTrade: !riskSettings?.disallowTransferFutureToTrade,
              allowTransferCustodyToFuture: !riskSettings?.disallowTransferCustodyToFuture,
              allowTransferFutureToCustody: !riskSettings?.disallowTransferFutureToCustody
            };

            return {
              ...x,
              final: {
                deposit: getWithdrawDepositPermissions(x.tokenType, 'deposit', userPermissions, allowDeposit, isEffect),
                withdraw: getWithdrawDepositPermissions(x.tokenType, 'withdraw', userPermissions, allowWithdraw, isEffect),
                transferCustodyToTrade: getTransferPermissions(userPermissions.allowTransferCustodyToTrade, tokenPermissions.allowTransferCustodyToTrade, isEffect),
                transferFiatToTrade: getTransferPermissions(userPermissions.allowTransferFiatToTrade, tokenPermissions.allowTransferFiatToTrade, isEffect),
                transferTradeToCustody: getTransferPermissions(userPermissions.allowTransferTradeToCustody, tokenPermissions.allowTransferTradeToCustody, isEffect),
                transferTradeToFiat: getTransferPermissions(userPermissions.allowTransferTradeToFiat, tokenPermissions.allowTransferTradeToFiat, isEffect),
                transferTradeToTrade: userPermissions.allowTransferTradeToTrade,
                optTransferOut: getTransferPermissions(userPermissions.allowOptTransferOut, true, isEffect),
                optTransferIn: true,
                transferTradeToFuture: getTransferPermissions(userPermissions.allowTransferTradeToFuture, tokenPermissions.allowTransferTradeToFuture, isEffect),
                transferFutureToTrade: getTransferPermissions(userPermissions.allowTransferFutureToTrade, tokenPermissions.allowTransferFutureToTrade, isEffect),
                transferCustodyToFuture: getTransferPermissions(userPermissions.allowTransferCustodyToFuture, tokenPermissions.allowTransferCustodyToFuture, isEffect),
                transferFutureToCustody: getTransferPermissions(userPermissions.allowTransferFutureToCustody, tokenPermissions.allowTransferFutureToCustody, isEffect)
              }
            };
          });
        };

        if (userStore.isLogin) {
          this.currencys = getPermissions(res.data);
        } else {
          this.currencys = res.data;
        }

        this.getRates();
      }
    },

    // 所有币对列表
    // force 强制刷新，走新的接口spotSymbolList
    async getSymbols(params = { force: false }) {
      const configStore = useConfigStore();
      if (this.symbols && this.symbols.length && !params.force) {
        return;
      }

      const data: any = await backoff(configStore.getConfig);

      // 先粗糙处理防重复赋值 引起的交易对变化
      if (this.symbols && this.symbols.length && !params.force) {
        return;
      }

      if (data.success) {
        const symbols = data.data.symbol.filter((item: any) => {
          // 过滤不需要展示的交易对
          if (!item.showStatus) {
            return false;
          }
          // TODO 其他过滤条件

          return true;
        })
          .map((item: any) => ({
            ...item,
            isWhite: (data.data.publicWhiteSymbols || []).includes(item.symbolId)
          }))
          .sort((a: any, b: any) => b.symbolWeight - a.symbolWeight);

        this.symbols = symbols;
      }
    },
    // 更新symbol信息
    async updateSymbol(symbolId: string) {
      const data: any = await $api.spotSymbolDetail({ symbolId: symbolId });
      if (data.success) {
        const symbols = JSON.parse(JSON.stringify(this.symbols));
        const index = symbols.findIndex((item: any) => item.symbolId === symbolId);
        if (index !== -1) {
          symbols[index] = data.data;
          this.symbols = symbols;
        }
      }
    },

    // 获取汇率
    getRates() {
      assetApi
        .getRates({})
        .then((data: any) => {
          if (data.success) {
            this.rates = data.data;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          clearTimeout(this.rateTime);
          this.rateTime = setTimeout(() => {
            this.getRates();
          }, 5000);
        });
    },

    // 清除汇率timeout
    clearRatesTimes() {
      clearTimeout(this.rateTime);
    },

    async getHotSymbol() {
      if (this.hotSymbols.length) {
        return;
      }

      const res: any = await $api.getHotSymbol({ number: 5 });

      if (res.success) {
        this.hotSymbols = res.data;
      }
    },
    async getWithdrawal() {
      const res:any = await $assets.quota_info({  token_id: 'BTC', chain_type: 'BTC' });
      if (res.code === 200) {
        this.isWithdrawal = res.data.refuseReason;
      }
    },
    // 资产下架或者不可见时返回true
    isAssetInvisible(tokenId: string) {
      const res = this.currencys?.find(item => item?.tokenId === tokenId);

      return !res || (res?.hasOwnProperty('status') && res?.status !== 1);
    },
    setFingerPrinted() {
      this.fingerPrinted = true;
    },
    async getHomeBaner() {
      const res: any = await agentApi.getHomeBanner();
      if (res.code === 200 && !_.isEmpty(res.data)) {
        const data = res.data;
        this.homeBanner = {
          'eventKey': data.eventKey,
          'imageUrl': data.imageUrl,
          'topLeftCorner': data.topLeftCorner,
          'title': [
            data.mainTitleFirst && JSON.parse(data.mainTitleFirst),
            data.mainTitleSecond && JSON.parse(data.mainTitleSecond),
            data.mainTitleThird && JSON.parse(data.mainTitleThird),
            data.mainTitleFourth && JSON.parse(data.mainTitleFourth)
          ],
          'subtitle': [
            data.subTitleFirst && JSON.parse(data.subTitleFirst),
            data.subTitleSecond && JSON.parse(data.subTitleSecond),
            data.subTitleThird && JSON.parse(data.subTitleThird),
            data.subTitleFourth && JSON.parse(data.subTitleFourth)
          ],
          'linkUrl': data.linkUrl,
          'disclaimer': JSON.parse(data.disclaimer)
        };
      }
    }
  }
});

// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 增长路由 15个
const growth = [
  // {
  //   path: '/safeWay',
  //   component: () => import('@/pages/safeWay/index.vue'),
  //   meta: {
  //     layout: 'default',
  //     title: t('护航计划 - 投资者教育企划 - HashKey Global - 买币更方便，存币更安心'),
  //     description: t('保护投资者权益是 HashKey Group 平台运营的基本立场，此次上线“护航计划”，将投资者教育作为一项重要工作长期进行。HashKey Global 作为香港合规交易所，希望与监管部门和行业共同努力，与公众开展积极沟通，宣传正确交易理念，推进数字资产市场的长期健康发展。'),
  //     ssg: {}
  //   }
  // },
  // {
  //   path: '/safeWay/Public-Courses/what-is-:id',
  //   alias: '/safeWay/Public-Courses/token-preview-:id',
  //   component: () => import('@/pages/safeWay/content.vue'),
  //   meta: {
  //     layout: 'default',
  //     ssg: {}
  //   }
  // },
  // {
  //   path: '/hashBrokerage',
  //   component: () => import('@/pages/hashBrokerage/Index.vue'),
  //   meta: {
  //     title: t('HashKey 数字货币经纪商项目 - HashKey Global - 买币更方便，存币更安心'),
  //     description: t('借助 HashKey 经纪商计划，您可以用 HashKey 交易所的技术打造自己的数字资产平台，并从每笔交易中分红。'),
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/hashBrokerage/' : ''
  // },
  // {
  //   path: '/h5/hashBrokerage',
  //   component: () => import('@/pages/hashBrokerage/h5/Index.vue'),
  //   meta: {
  //     layout: 'h5Default',
  //     title: t('HashKey 数字货币经纪商项目 - HashKey Global'),
  //     ssg: {}
  //   }
  // },
  // {
  //   path: '/h5/hashBrokerage/concatUs',
  //   component: () => import('@/pages/hashBrokerage/h5/contactUs.vue'),
  //   meta: {
  //     layout: 'h5Default',
  //     title: t('HashKey Brokerage'),
  //     ssg: {}
  //   }
  // },
  // {
  //   path: '/reverseCustomerAcquisition',
  //   component: () => import('@/pages/reverseCustomerAcquisition/Index.vue'),
  //   meta: {
  //     title: t('HashKey Global'),
  //     description: 'Welcome to HashKey Global, where you can conveniently trade between fiat and cryptocurrencies. As a highly recognized digital asset trading platform in APAC, we provide a safe and secure trading environment based on licensing compliance. With HashKey Global, you will enjoy a fast, secure and convenient trading experience.',
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   }
  // },
  // {
  //   path: '/h5/reverseCustomerAcquisition',
  //   component: () => import('@/pages/reverseCustomerAcquisition/H5.vue'),
  //   meta: {
  //     layout: 'h5Default',
  //     description: 'Welcome to HashKey Global, where you can conveniently trade between fiat and cryptocurrencies. As a highly recognized digital asset trading platform in APAC, we provide a safe and secure trading environment based on licensing compliance. With HashKey Global, you will enjoy a fast, secure and convenient trading experience.',
  //     title: t('HashKey Global'),
  //     ssg: {}
  //   }
  // },
  {
    path: '/hsk/history',
    component: () => import('@/pages/hsk/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      ssg: {}
    }
  },
  {
    path: '/hsk',
    component: () => import('@/pages/hskPoints/Index.vue'),
    meta: {
      layout: 'default',
      title: t('HSK 代币 - 什么是 HSK？- HashKey Global - 买币更方便，存币更安心'),
      description: t('HSK 是基于标准 ERC-20 协议的功能型代币。HSK 将激励所有 HashKey 生态贡献者，并为整个生态发展提供动力。'),
      props: { footer: { theme: 'dark', border: false } },
      ssg: {}
      //   auth: 'login'
    }
  },
  {
    path: '/h5/hsk',
    component: () => import('@/pages/hskPoints/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HSK 代币 - 什么是 HSK？- HashKey Global - 买币更方便，存币更安心'),
      description: t('HSK 是基于标准 ERC-20 协议的功能型代币。HSK 将激励所有 HashKey 生态贡献者，并为整个生态发展提供动力。HashKey Global 作为香港持牌交易所，将率先支持 HSK 应用场景的拓展'),
      ssg: {}
      // layout: 'default',
      //   auth: 'login'
    }
  },
  {
    path: '/hskPoints',
    component: () => import('@/pages/hskPoints/Index.vue'),
    meta: {
      layout: 'default',
      title: t('HSK 代币 - 什么是 HSK？- HashKey Global - 买币更方便，存币更安心'),
      description: t('HSK 是基于标准 ERC-20 协议的功能型代币。HSK 将激励所有 HashKey 生态贡献者，并为整个生态发展提供动力。HashKey Global 作为香港持牌交易所，将率先支持 HSK 应用场景的拓展'),
      props: { footer: { theme: 'dark', border: false } },
      ssg: {}
      //   auth: 'login'
    },
    redirect: '/hsk'
  },
  {
    path: '/h5/hskPoints',
    component: () => import('@/pages/hskPoints/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HSK 代币 - 什么是 HSK？- HashKey Global - 买币更方便，存币更安心'),
      description: t('HSK 是基于标准 ERC-20 协议的功能型代币。HSK 将激励所有 HashKey 生态贡献者，并为整个生态发展提供动力。HashKey Global 作为香港持牌交易所，将率先支持 HSK 应用场景的拓展'),
      ssg: {}
      // layout: 'default',
      //   auth: 'login'
    },
    redirect: '/h5/hsk'
  },
  // {
  //   path: '/tradeHsk',
  //   component: () => import('@/pages/tradeHsk/Index.vue'),
  //   meta: {
  //     layout: 'default',
  //     title: t('交易有奖大放送'),
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/tradeHsk' : ''
  // },
  // {
  //   path: '/h5/tradeHsk',
  //   component: () => import('@/pages/tradeHsk/h5/Index.vue'),
  //   meta: {
  //     layout: 'h5Default',
  //     title: t('交易有奖大放送'),
  //     ssg: {}
  //   }
  // },
  {
    path: '/invite',
    component: () => import('@/pages/invite/Index.vue'),
    meta: {
      layout: 'default',
      title: t('邀请返佣-invite'),
      props: { footer: { theme: 'dark', border: false } },
      ssg: {}
    },
    redirect: !isPC() ? '/invite/h5' : ''
  },
  // {
  //   path: '/invite/record',
  //   component: () => import('@/pages/invite/record.vue'),
  //   meta: {
  //     layout: 'default',
  //     auth: 'login',
  //     title: t('邀请记录'),
  //     ssg: {}
  //   }
  // },
  // {
  //   path: '/buy-crypto',
  //   component: () => import('@/pages/buy-cryptos/web/Index.vue'),
  //   meta: {
  //     title: t('如何购买数字货币 - HashKey Global'),
  //     description: t('想了解如何安全购买数字货币？HashKey Global 学院提供详细指南，帮助您迈出第一步。掌握购买流程、安全技巧，开始数字资产投资之旅！'),
  //     keyword: t('购买数字货币，数字资产投资，HashKey Global 学院，安全购币指南，加密货币购买步骤'),
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/buy-crypto' : ''
  // },
  // {
  //   path: '/buy-crypto/bitcoin',
  //   component: () => import('@/pages/buy-cryptos/web/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买比特币（Bitcoin）BTC？'),
  //     description: t('如果您想要购买比特币（Bitcoin），那么 HashKey Global 是一个不错的选择。HashKey Global 是一家专业的数字资产交易平台，支持多种比特币交易对，包括 BTC/USD 和 BTC/HKD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('比特币交易所、法币兑换比特币、HashKey Global，比特币交易平台、比特币交易费用、比特币交易安全、法币兑换比特币方法、法币兑换比特币流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/buy-crypto/bitcoin' : ''
  // },
  // {
  //   path: '/h5/buy-crypto/bitcoin',
  //   component: () => import('@/pages/buy-cryptos/h5/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买比特币（Bitcoin）BTC？'),
  //     description: t('如果您想要购买比特币（Bitcoin），那么 HashKey Global 是一个不错的选择。HashKey Global 是一家专业的数字资产交易平台，支持多种比特币交易对，包括 BTC/USD 和 BTC/HKD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('比特币交易所、法币兑换比特币、HashKey Global，比特币交易平台、比特币交易费用、比特币交易安全、法币兑换比特币方法、法币兑换比特币流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'h5Default',
  //     ssg: {}
  //   },
  //   redirect: isPC() ? '/buy-crypto/bitcoin' : ''
  // },
  // {
  //   path: '/buy-crypto/eth',
  //   component: () => import('@/pages/buy-cryptos/web/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买以太坊（Ethereum）ETH？'),
  //     description: t('如果您想要购买以太坊（Ethereum），那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种以太坊交易对，包括ETH/USD和ETH/HKD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('以太坊交易所、法币兑换以太坊、HashKey Global，以太坊交易平台、以太坊交易费用、以太坊交易安全、法币兑换以太坊方法、法币兑换以太坊流程、HashKey Global 优势、HashKey Global 评价、HashKey Global 教程'),
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/buy-crypto/eth' : ''
  // },
  // {
  //   path: '/h5/buy-crypto/eth',
  //   component: () => import('@/pages/buy-cryptos/h5/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买以太坊（Ethereum）ETH？'),
  //     description: t('如果您想要购买以太坊（Ethereum），那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种以太坊交易对，包括ETH/USD和ETH/HKD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('以太坊交易所、法币兑换以太坊、HashKey Global，以太坊交易平台、以太坊交易费用、以太坊交易安全、法币兑换以太坊方法、法币兑换以太坊流程、HashKey Global 优势、HashKey Global 评价、HashKey Global 教程'),
  //     layout: 'h5Default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: isPC() ? '/buy-crypto/eth' : ''
  // },
  // {
  //   path: '/buy-crypto/usdt',
  //   component: () => import('@/pages/buy-cryptos/web/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买泰达币（Tether USDt）USDT？'),
  //     description: t('如果您想要购买泰达币（USDT），那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种泰达币交易对，包括USDT/USD和USDT/HKD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('泰达币交易所、法币兑换泰达币、HashKey Global，泰达币交易平台、泰达币交易费用、泰达币交易安全、法币兑换泰达币方法、法币兑换泰达币流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/buy-crypto/usdt' : ''
  // },
  // {
  //   path: '/h5/buy-crypto/usdt',
  //   component: () => import('@/pages/buy-cryptos/h5/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买泰达币（Tether USDt）USDT？'),
  //     description: t('如果您想要购买泰达币（USDT），那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种泰达币交易对，包括USDT/USD和USDT/HKD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('泰达币交易所、法币兑换泰达币、HashKey Global，泰达币交易平台、泰达币交易费用、泰达币交易安全、法币兑换泰达币方法、法币兑换泰达币流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'h5Default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: isPC() ? '/buy-crypto/usdt' : ''
  // },
  // {
  //   path: '/buy-crypto/avax',
  //   component: () => import('@/pages/buy-cryptos/web/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买 Avalanche (AVAX)？'),
  //     description: t('如果您想要购买 Avalanche (AVAX)，那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种AVAX交易对，包括AVAX/USD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('AVAX交易所、法币兑换AVAX、HashKey Global，AVAX交易平台、AVAX交易费用、AVAX交易安全、法币兑换AVAX方法、法币兑换AVAX流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/buy-crypto/avax' : ''
  // },
  // {
  //   path: '/h5/buy-crypto/avax',
  //   component: () => import('@/pages/buy-cryptos/h5/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买 Avalanche (AVAX)？'),
  //     description: t('如果您想要购买 Avalanche (AVAX)，那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种AVAX交易对，包括AVAX/USD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('AVAX交易所、法币兑换AVAX、HashKey Global，AVAX交易平台、AVAX交易费用、AVAX交易安全、法币兑换AVAX方法、法币兑换AVAX流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'h5Default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: isPC() ? '/buy-crypto/avax' : ''
  // },
  // {
  //   path: '/buy-crypto/spice',
  //   component: () => import('@/pages/buy-cryptos/web/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买 SPICE？'),
  //     description: t('如果您想要购买 SPICE，那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种SPICE交易对，包括SPICE/USD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('SPICE交易所、法币兑换SPICE、HashKey Global，SPICE交易平台、SPICE交易费用、SPICE交易安全、法币兑换SPICE方法、法币兑换SPICE流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: !isPC() ? '/h5/buy-crypto/spice' : ''
  // },
  // {
  //   path: '/h5/buy-crypto/spice',
  //   component: () => import('@/pages/buy-cryptos/h5/currency.vue'),
  //   meta: {
  //     title: t('如何在 HashKey Global 购买 SPICE？'),
  //     description: t('如果您想要购买 SPICE，那么HashKey Global是一个不错的选择。HashKey Global是一家专业的数字资产交易平台，支持多种SPICE交易对，包括SPICE/USD，让您可以轻松地在法币和加密货币之间进行兑换。'),
  //     keywords: t('SPICE交易所、法币兑换SPICE、HashKey Global，SPICE交易平台、SPICE交易费用、SPICE交易安全、法币兑换SPICE方法、法币兑换SPICE流程、HashKey Global优势、HashKey Global评价、HashKey Global教程'),
  //     layout: 'h5Default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: isPC() ? '/buy-crypto/spice' : ''
  // },
  // {
  //   path: '/h5/buy-crypto',
  //   component: () => import('@/pages/buy-cryptos/h5/Index.vue'),
  //   meta: {
  //     title: t('HashKey 数字货币经纪商项目 - HashKey Global - 买币更方便，存币更安心'),
  //     description: t('借助 HashKey 经纪商计划，您可以用 HashKey 交易所的技术打造自己的数字资产平台，并从每笔交易中分红。'),
  //     layout: 'h5Default',
  //     props: { footer: { theme: 'dark', border: false } },
  //     ssg: {}
  //   },
  //   redirect: isPC() ? '/buy-crypto' : ''
  // },
  {
    path: '/invite/h5',
    // 因为 APP 地址写成大写并且已经发布了，前端做一下兼容。后期 APP 强更的时候就可以去除掉了。
    alias: '/h5/invite',
    component: () => import('@/pages/invite/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('邀请好友加入 - HashKey Global - 买币更方便，存币更安心'),
      description: t('邀请好友加入 HashKey Global，享受交易优惠和HSK奖励。'),
      ssg: {}
    },
    redirect: isPC() ? '/invite' : ''
  },
  // {
  //   path: '/h5/invite/information',
  //   component: () => import('@/pages/invite/h5/h5Information.vue'),
  //   meta: {
  //     layout: 'h5Default',
  //     title: t('大使认证申请'),
  //     ssg: {}
  //   }
  // },
  {
    path: '/vipZone',
    component: () => import('@/pages/vipZone/web/Index.vue'),
    meta: {
      layout: 'default',
      title: 'VIP',
      props: { footer: { theme: 'dark', border: false } },
      ssg: {}
    },
    redirect: !isPC() ? '/h5/vipZone' : ''
  },
  {
    path: '/h5/vipZone',
    component: () => import('@/pages/vipZone/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: 'VIP',
      ssg: {}
    },
    redirect: isPC() ? '/vipZone' : ''
  },
  {
    path: '/launch',
    component: () => import('@/pages/launch/web/Index.vue'),
    meta: {
      layout: 'default',
      title: t('HashKey Global Launchpool {\'|\'} 挖矿、收益、加密货币投资平台'),
      keywords: t('HashKey Global，Launchpool，挖矿，加密货币，投资，区块链项目，质押收益，早期投资'),
      description: t('加入HashKey Global的Launchpool，通过质押加密货币赚取高收益。了解最新项目，参与创新区块链项目的早期投资。立即注册，开始您的加密货币投资之旅。'),
      props: { footer: { theme: 'dark', border: false } },
      ssg: {}
    },
    redirect: !isPC() ? '/h5/launch' : ''
  },
  {
    path: '/launchpool/detail/:id',
    component: () => import('@/pages/launch/web/launchpool/Index.vue'),
    meta: {
      layout: 'default',
      title: 'HashKey Launchpool',
      props: { footer: { theme: 'dark', border: false } },
      ssg: {}
    },
    redirect: !isPC() ? '/h5/launch' : ''
  },
  {
    path: '/launchpool/pledge/:id',
    component: () => import('@/pages/launch/web/launchpool/Pledge.vue'),
    meta: {
      layout: 'default',
      title: 'HashKey Launchpool',
      auth: 'login',
      ssg: {}
    },
    redirect: !isPC() ? '/h5/launch' : ''
  },
  {
    path: '/launch/user',
    component: () => import('@/pages/launch/web/User.vue'),
    meta: {
      layout: 'default',
      title: 'HashKey Launchpool',
      auth: 'login',
      ssg: {}
    },
    redirect: !isPC() ? '/h5/launch' : ''
  },
  {
    path: '/launch/extra',
    component: () => import('@/pages/launch/web/Extra.vue'),
    meta: {
      layout: 'default',
      title: 'HashKey Launchpool',
      props: { footer: { theme: 'dark', border: false } },
      ssg: {}
    },
    redirect: !isPC() ? '/h5/launch/extra' : ''
  },
  {
    path: '/h5/launch',
    component: () => import('@/pages/launch/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HashKey Global Launchpool {\'|\'} 挖矿、收益、加密货币投资平台'),
      keywords: t('HashKey Global，Launchpool，挖矿，加密货币，投资，区块链项目，质押收益，早期投资'),
      description: t('加入HashKey Global的Launchpool，通过质押加密货币赚取高收益。了解最新项目，参与创新区块链项目的早期投资。立即注册，开始您的加密货币投资之旅。'),
      ssg: {}
    },
    redirect: isPC() ? '/launch' : ''
  },
  {
    path: '/h5/launchpool/detail/:id',
    component: () => import('@/pages/launch/h5/LaunchPool.vue'),
    meta: {
      layout: 'h5Default',
      title: 'HashKey Launchpool',
      ssg: {}
    },
    redirect: isPC() ? '/launch' : ''
  },
  {
    path: '/h5/launchpool/pledge/:id',
    component: () => import('@/pages/launch/h5/Pledge.vue'),
    meta: {
      layout: 'h5Default',
      title: t('质押日历'),
      ssg: {}
    },
    redirect: isPC() ? '/launch' : ''
  },
  {
    path: '/h5/launch/user',
    component: () => import('@/pages/launch/h5/User.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HashKey Launchpool 用户中心'),
      ssg: {}
    },
    redirect: isPC() ? '/launch' : ''
  },
  // 代理返佣落地页
  {
    path: '/invite-agent',
    component: () => import('@/pages/invite-agent/index.vue'),
    meta: {
      layout: 'default',
      title: t('invite.agent.title'),
      ssg: {}
    },
    redirect: !isPC() ? '/h5/invite-agent' : ''
  },
  // 代理返佣落地页-h5
  {
    path: '/h5/invite-agent',
    component: () => import('@/pages/invite-agent/h5/index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('invite.agent.title'),
      ssg: {}
    },
    redirect: isPC() ? '/invite-agent' : ''
  },
  // 代理返佣【数据概览/一级邀请/二级邀请/佣金收入】
  {
    path: '/invite-agent/:type',
    component: () => import('@/pages/invite-agent/[type].vue'),
    meta: {
      layout: 'default'
    },
    redirect: !isPC() ? '/h5/invite-agent/:type' : ''
  },
  // 代理返佣【数据概览/一级邀请/二级邀请/佣金收入】-h5
  {
    path: '/h5/invite-agent/:type',
    component: () => import('@/pages/invite-agent/h5/[type].vue'),
    meta: {
      layout: 'h5Default'
    },
    redirect: isPC() ? '/invite-agent/:type' : ''
  },
  {
    path: '/h5/launch/extra',
    component: () => import('@/pages/launch/h5/Extra.vue'),
    meta: {
      layout: 'h5Default',
      title: 'HashKey Launchpool',
      ssg: {}
    },
    redirect: isPC() ? '/launch/extra' : ''
  }
];

export default growth;

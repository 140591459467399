/**
 * @file 现货交易
 *
 * #暂时不定义 interface、type 一起做any script战士
 */
import { formatTime } from '@/utils/time';
import { isClient } from '@vueuse/shared';
import md5 from 'md5';
import sha256 from 'js-sha256';

import {
  getSymbolPrecision,
  floatToPrecisionInt,
  currencyValue,
  amountFormat
} from '@/utils/tools';
import WSDATA from '@/utils/data_source';
import { useConfigStore } from '@/store/config';
import { useCommonStore } from '@/store/common';
import { useWsStore } from '@/store/ws';
import { useUserStore } from '@/store/User';
import { spotApi } from '@/config/api';
import { gt, plus, lt, toFixed, times } from '@/utils/number';
import { excludeRepeatArray } from '@/utils/tools';
import { toast } from '@/utils/toast';
import { pinia } from './index';
import { i18n } from '@/lang/i18n';


const { t } = i18n.global;
const configStore = useConfigStore(pinia);
const commonStore = useCommonStore(pinia);
const userStore = useUserStore(pinia);
const wsStore = useWsStore(pinia);

interface OrderBookRow {
  price: string;
  amount: string;
  sum: string;
}

// localStorage 存储key 名称
const saveKey: any = {
  login: 'favorites-',
  noLogin: 'favorites-no-login'
};

// 盘口计算工具函数
const orderBookUtil = {
  /**
   * 盘口列表排序
   *
   * @param array 盘口列表
   * @param type 排序方式： asc-升  desc-降
   */
  listSort: (array: Array<Array<string>>, type = 'asc') => {
    // 若接口已排序 用reverse()就行
    array.sort((a: Array<string>, b: Array<string>) => {
      // 理论上接口不会返回两个一样的价钱
      if (a[0] === b[0]) {
        return 0;
      }

      const typeNum = type === 'asc' ? 1 : -1;

      return gt(a[0], b[0]) ? typeNum : -typeNum;
    });
  },
  /**
   * 计算盘口累计数量、数据处理
   *
   * @param array 盘口列表
   * @param precision 价格精度
   */
  calcuAmount: (array: Array<Array<string>>, pricePrecision: number, amountPrecision: number) => {
    let sum = 0;
    return array.map(item => {
      const [price, amount] = item;
      sum = plus(amount, sum);
      // sum = index > 0 ? plus(array[index - 1][1], amount) : amount;

      return {
        price: toFixed(price, pricePrecision),
        priceOrigin: price,
        amount: amountFormat(amount, amountPrecision),
        amountOrigin: amount,
        amountFixed: toFixed(amount, amountPrecision),
        sum: amountFormat(sum, amountPrecision),
        sumOrigin: sum,
        sumFixed: toFixed(sum, amountPrecision),
        amountPrecision,
        pricePrecision
      };
    });
  },
  // 合并深度数据
  mergeDepth: (source: Array<string>, target: Array<string>) => {
    const updateItem: any = {};

    target.forEach((tTtem: any) => {
      source.some((sItem: any, index: number) => {
        if (tTtem[0] === sItem[0]) {
          updateItem[tTtem[0]] = tTtem;

          if (Number(tTtem[1]) !== 0) {
            sItem[1] = tTtem[1];
          } else {
            // 数量为0说明成交了
            source.splice(index, 1);
          }
          return true;
        }

        return false;
      });

      if (!updateItem[tTtem[0]] && Number(tTtem[1]) !== 0) {
        // 新数据
        source.push(tTtem);
      }
    });
  }
};

export const useSpotStore = defineStore('spotStore', {
  state: () => ({
    qws: <any | null>null,
    // 当前交易对
    currSymbol: <any>{},
    // quotationData: <any>{},
    // 当前交易对盘口数据
    orderBookData: {
      ask: <Array<OrderBookRow>>[],
      bid: <Array<OrderBookRow>>[],
      source: <any>{ ask: [], bid: [] }
    },
    // 盘口最后一次请求参数（用于跟返回数据比较）
    orderBookParams: <any>{},
    // 最新成交
    lastTrades: <Array<any>>[],
    // 临时保存上一次请求或请阅的参数
    lastSubParams: {
      orderBook: <any>{},
      lastTrades: <any>{}
    },
    // 交易对收藏
    favorites: <Array<string>>[],
    // 是否允许切换交易对列表
    switchSymbolTabEnable: true,
    // 涨幅榜
    rateTopRes: <Array<any>>[],
    subs: <Array<any>>[],
    reloadKline: <string>'1',
    // 交易引导
    spotGuide: <any>{
      step: 0,
      done: true
    }
  }),
  getters: {
    /**
     * 行情订阅数据
     */
    quotationData() {
      return wsStore.quotationData;
    },
    /**
     * 所有交易对行情（结构如下）
     *
     * { BTCUSDT: {...行情数据}, ETHUSDT: {...行情数据}, ... }
     */
    quotation() {
      const quotation: any = {};
      const quotationData = wsStore.quotationData;

      Object.keys(quotationData).forEach(symbol => {
        const symbolQuota: any = {
          ...quotationData[symbol],
          priceText: '-',
          rateText: '-'
        };
        const rate = symbolQuota.m;
        const symbolInfo = commonStore.symbolMap[symbol];

        // 修改成不是跌就是涨
        if (lt(rate, 0)) {
          symbolQuota.rateClass = 'down';
        } else {
          symbolQuota.rateClass = 'up';
        }

        if (symbolInfo) {
          const { minPricePrecision } = symbolInfo;
          const pricePrecision = floatToPrecisionInt(minPricePrecision);
          symbolQuota.priceText = toFixed(symbolQuota.c, pricePrecision);
          symbolQuota.rateText = `${toFixed(times(rate, 100), 2, 1)}%`;
        }

        quotation[symbol] = {
          ...symbolQuota
        };
      });

      return quotation;
    },
    /**
     * 涨幅榜
     */
    rateTop(state) {
      const quotation: any = {};

      state.rateTopRes.forEach(item => {
        const symbolQuota: any = { ...item };
        const rate = symbolQuota.m;
        // 修改成不是跌就是涨
        if (lt(rate, 0)) {
          symbolQuota.rateClass = 'down';
        } else {
          symbolQuota.rateClass = 'up';
        }

        quotation[item.s] = {
          ...symbolQuota
        };
      });

      return quotation;
    },
    // 当前交易对-基础货币名称
    baseTokenName(state) {
      return state.currSymbol.baseTokenName || '';
    },
    // 当前交易对-计价货币名称
    quoteTokenName(state) {
      return state.currSymbol.quoteTokenName || '';
    },
    // 盘口精度合并选项
    mergePrecisionOptions(state) {
      return getSymbolPrecision(state.currSymbol);
    },
    // 当前交易对行情最新价（返回币价和折算价）
    lastPrice(state) {
      const { symbolId, quoteTokenId, minPricePrecision, isWhite, canTrade, tradingStatus } = state.currSymbol;
      const symbolQuote: any = state.quotationData[symbolId];

      // 开盘倒计时新增白名单 或 可交易 或 停盘（4、5、6） 或 复牌（7）状态时展示价格
      if (symbolQuote && commonStore.rates && (isWhite || canTrade || [4, 5, 6, 7].includes(tradingStatus))) {
        const rates: any = { quoteTokenId: {} };
        commonStore.rates.forEach((it: any) => {
          if (it.token === quoteTokenId) {
            rates[quoteTokenId] = it.rates;
          }
        });

        const res = currencyValue(
          rates,
          symbolQuote ? symbolQuote.c : 0,
          quoteTokenId
        );

        // 涨幅
        const rate = symbolQuote.m;
        let dir = '';
        // if (gt(rate, 0)) {
        //   dir = 'up';
        // } else if (lt(rate, 0)) {
        //   dir = 'down';
        // }
        // 不是跌就是涨
        if (lt(rate, 0)) {
          dir = 'down';
        } else {
          dir = 'up';
        }

        return {
          quote: symbolQuote.c,
          quoteText: toFixed(
            symbolQuote.c,
            floatToPrecisionInt(minPricePrecision)
          ),
          currency: res[1],
          unit: res[0],
          dir
        };
      }

      return {
        quote: '-',
        quoteText: '-',
        currency: '-',
        unit: isClient ? window.localStorage.legal_flag : '',
        dir: ''
      };
    },
    buy1Price(state) {
      return state.orderBookData.bid[0]
        ? state.orderBookData.bid[0].price
        : 0;
    },
    sell1Price(state) {
      return state.orderBookData.ask[0]
        ? state.orderBookData.ask[0].price
        : 0;
    }
  },
  actions: {
    initGuide() {
      this.spotGuide = { step: 0, done: true }; // 重置交易引导
    },
    changeGuide(val: any) {
      if (userStore.isLogin) {
        if (!import.meta.env.SSR) {
          if (JSON.stringify(val) == '{}') {
            window.scrollTo(0, 0);
          } else if (val?.step == 1) {
            window.scrollTo(1200, 0);
          } else if (val?.step == 2) {
            window.scrollTo(0, 200);
          } else if (val?.step == 3) {
            window.scrollTo(0, 1400);
          } else if (val?.step == 4) {
            window.scrollTo(0, 200);
          }
        }

        this.spotGuide = { step: 0, done: localStorage.getItem('spotGuide') || false };
        this.spotGuide = { ...this.spotGuide, ...val };
        if (this.spotGuide.done) {
          localStorage.setItem('spotGuide', this.spotGuide.done);
        }
      }
    },
    /**
     * 取消订阅
     *
     * @param subId 订阅ID
     */
    unSub(subId: string) {
      subId && wsStore.quoteWS?.cancel(subId);
    },
    // 订阅所有币种行情
    subQuotation() {
      wsStore.quoteWS?.sub(
        {
          event: 'sub',
          id: 'broker',
          topic: 'slowBroker',
          params: {
            org: configStore.orgId,
            realtimeInterval: '24h',
            binary: import.meta.env.MODE === 'production'
          }
        },
        () => {
          // TODO
        },
        (res: any) => {
          res.data.forEach((item: any) => {
            this.quotationData[item.s] = item;
          });
        }
      );
    },
    // 订阅盘口数据
    subOrderBook(params: any) {
      this.orderBookData = {
        ask: [],
        bid: [],
        source: { ask: [], bid: [] } // 清空盘口
      };
      // 先取消订阅上一次
      if (
        this.orderBookParams.subDepthId
      ) {
        this.orderBookData.ask = [];
        this.orderBookData.bid = [];
        this.unSub(this.orderBookParams.subDepthId);
      }

      const { exchangeId, symbolId, depthPrecision, limit = 30, basePrecision, minPricePrecision } = params;
      const id = `${exchangeId}.${symbolId}${depthPrecision}`;
      this.orderBookParams = {
        ...params,
        subDepthId: id
      };

      wsStore.quoteWS?.sub(
        {
          id,
          topic: 'diffMergedDepth',
          event: 'sub',
          symbol: `${exchangeId}.${symbolId}`,
          limit,
          params: {
            dumpScale:
              depthPrecision <= 0 ? depthPrecision - 1 : depthPrecision,
            binary: import.meta.env.MODE === 'production'
          }
        },
        () => null,
        (res: any) => {
          // 价格精度
          const {
            // depthPrecision,
            symbolId,
            subDepthId
            // subDepthVersion = ''
          } = this.orderBookParams;
          const { data, id, f } = res;
          // 相同订阅，根据当前收到数据的 vs 匹配已记录的 ve， 匹配不上，进行重新订阅
          if (res.symbol === symbolId && id === subDepthId && data && this.currSymbol.symbolId == res.symbol) {
            // 首次订阅
            // if (res.f) {
            //     this.orderBookParams.subDepthVersion = '';
            // } else {
            // 本次返回版本号
            const version = data[0] ? data[0].o : '';
            // if (
            //   subDepthVersion &&
            //   version &&
            //   Number(version) - Number(subDepthVersion) != 1
            // ) {
            //   console.log('lalal', data, version, subDepthVersion);
            //   // 不是正确订阅，将进行重新订阅 diffMergedDepth
            //   this.unSub(subDepthId);
            //   this.orderBookParams.subDepthVersion = '';
            //   this.subOrderBook(this.orderBookParams);
            //   return;
            // }
            // }

            if (data.length) {
              const { a = [], b = [] } = data[0];

              if (f) {
                this.orderBookData.source = { ask: a, bid: b };
              } else {
                orderBookUtil.mergeDepth(this.orderBookData.source.ask, a);
                orderBookUtil.mergeDepth(this.orderBookData.source.bid, b);
              }

              const { ask, bid } = this.orderBookData.source;

              // 排序
              orderBookUtil.listSort(ask);
              orderBookUtil.listSort(bid, 'desc');

              // 盘口价格精度
              // 取聚合精度
              // const pricePrecision = depthPrecision;

              // 取价格精度
              const pricePrecision = floatToPrecisionInt(minPricePrecision);
              // 数量精度
              const amountPrecision = floatToPrecisionInt(basePrecision);

              this.orderBookData.ask = orderBookUtil.calcuAmount(ask, pricePrecision, amountPrecision);

              this.orderBookData.bid = orderBookUtil.calcuAmount(
                bid,
                pricePrecision,
                amountPrecision
              );

              // 记录请求版本号
              this.orderBookParams.subDepthVersion = String(version);
            }
          }
        }
      );
    },
    // 订阅最新成交
    subLastTrades(params: any) {
      this.lastTrades = []; // 清除老的成交记录
      const { lastSubId } = this.lastSubParams.lastTrades;
      if (lastSubId) {
        this.unSub(lastSubId);
      }
      const { exchangeId, symbolId, basePrecision, minPricePrecision } = params;
      const subiId = `trade${exchangeId}.${symbolId}`;

      this.lastSubParams.lastTrades = { ...params, lastSubId: subiId };

      wsStore.quoteWS.sub(
        {
          id: `trade${exchangeId}.${symbolId}`,
          topic: 'trade',
          event: 'sub',
          limit: configStore.lastTradesMax,
          symbol: `${exchangeId}.${symbolId}`,
          params: {
            org: configStore.orgId,
            binary: import.meta.env.MODE === 'production'
          }
        },
        () => null,
        (res: any) => {
          if (res.f) {
            WSDATA.clear('newTradeSource');
          }

          WSDATA.setData('newTradeSource', res.data, res.id);

          const data = (WSDATA.getData('newTradeSource') || {})[res.id];

          if (data && this.currSymbol.symbolId == res.symbol) {
            this.lastTrades = excludeRepeatArray('v', data)
              .map((item: any) => {
                return {
                  price: toFixed(item.p, floatToPrecisionInt(minPricePrecision)),
                  amount: amountFormat(item.q, floatToPrecisionInt(basePrecision)),
                  time: formatTime(item.t, 'HH:mm:ss'),
                  dir: item.m ? 'buy' : 'sell'
                };
              });
          }
        }
      );
    },
    // 订阅涨幅榜
    subRateTop(reqType: any) {
      // 先取消订阅
      this.unSub('topN');
      const params: any = {
        realtimeInterval: '24h',
        binary: import.meta.env.MODE === 'production',
        limit: '10'
      };

      if (reqType) {
        params.type = reqType;
      }
      wsStore.quoteWS?.sub(
        {
          id: 'topN',
          topic: 'topN',
          event: 'sub',
          symbol: '',
          params
        },
        () => null,
        (res: any) => {
          this.rateTopRes = res.data;
        }
      );
    },
    // 添加自选
    favoriteAdd: async (params: any) => {
      try {
        const res: any = await spotApi.favoriteAdd({
          symbol_id: params.symbolId,
          exchange_id: params.exchangeId,
          type: params.type
        });

        if(res.success) {
          toast.success(t('已添加自选'));
        } else {
          toast.error(t('market_favorite_add_error'));
          userStore.getUserinfo();
        }

      } catch (e) {
        toast.error(t('market_favorite_add_error'));
      }
    },
    // 取消自选
    favoriteCancel: async (params: any) => {
      try {
        const res: any =  await spotApi.favoriteCancel({
          symbol_id: params.symbolId,
          exchange_id: params.exchangeId,
          type: params.type
        });
  
        if (res.success) {
          toast.success(t('已移除自选'));
        } else {
          toast.error(t('market_favorite_add_error'));
          userStore.getUserinfo();
        }
       
      } catch (e) {
        toast.error(t('market_favorite_add_error'));
      }
    },
    /**
     * 交易对自选状态改变
     *
     * @param symbol 交易对
     */
    favoritesChnage(symbol: any) {
      const { symbolId, exchangeId, type } = symbol;
      let isAdd = true;

      if (this.favorites.includes(symbolId)) {
        this.favorites.splice(this.favorites.indexOf(symbolId), 1);
        isAdd = false;
      } else {
        this.favorites.push(symbolId);
      }

      this.setFavorites({ exchangeId, symbolId, type }, isAdd);
    },
    /**
     * 交易对自选-保存
     *
     * @param params 参数
     * @param isAdd true-增加  false-取消
     */
    setFavorites(params: any, isAdd: boolean) {
      const { userId = '' } = userStore.userinfo;
      let Key = md5(saveKey.noLogin);

      if (userStore.isLogin) {
        Key = md5(`${saveKey.login}${userId}`);
        // 登录状态需要保存接口
        isAdd ? this.favoriteAdd(params) : this.favoriteCancel(params);
      }

      const str = String(this.favorites);
      isClient && localStorage.setItem(Key, str);
    },
    /**
     * 交易对自选-获取
     *
     * @returns
     */
    getFavorites() {
      const { userId = '' } = userStore.userinfo;
      let Key = md5(saveKey.noLogin);

      if (userStore.isLogin) {
        Key = md5(`${saveKey.login}${userId}`);
        const userFavorites = userStore.userinfo.favorites;
        // 接口有数据优先取接口
        // 如果 favorites 为空则赋值，防止已经有数据重复取了 uesrinfo 里的旧数据
        if (userFavorites.length && Array.isArray(this.favorites) && this.favorites.length === 0) {
          return (this.favorites = userFavorites.map(
            (item: any) => item.symbolId
          ));
        }
      } else {
        const favoritesStr: string = isClient ? (localStorage.getItem(Key) || '') : '';
        this.favorites = favoritesStr.split(',');
      }
    }
  }
});


<template>
  <el-dialog
    v-model="dialogVisible"
    title="Waring"
    :width="isPC() ? '400px' : '319px'"
    :modal="true"
    :show-close="false"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    modal-class="ipWarningModal"
    :class="isPC() ? 'ipWarningDialog' : 'ipWarningDialog-mobile'"
  >
    <div class="header flex-row items-center flex-row-center">
      <div class="icon-warning" />
    </div>
    <p class="text-sm-r gray-700 text-align-left mt16">
      {{ t('ip_warning_tips') }}
    </p>
    <hk-button
      class="false-primary-md text-sm-m h40 base-white w-full"
      :class="isPC() ? 'radius8 mt32' : 'radius20 mt24'"
      @click="handleClose"
    >
      {{ $t('我知道了') }}
    </hk-button>
  </el-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { isPC } from '@/utils/tools';
import eventTrack from '@/utils/eventTracking';
const { t } = useI18n();
const emits = defineEmits(['up-visible', 'confirm']);
const props = defineProps({
  visible: Boolean,
  type: {
    type: Number,
    default: 1
  }
});
const dialogVisible = computed(() => {
  return props.visible;
});
const handleClose = () => {
  // 确认埋点
  if (isPC()) {
    eventTrack({ name: 'Web_click_USAbanpop' });
  } else {
    eventTrack({ name: 'H5_click_USAbanpop' });
  }
  localStorage.setItem('usIpWarning', 'true');
  emits('confirm');
  emits('up-visible', false);
};
onMounted(async() => {
  // 弹窗埋点
  if (isPC()) {
    eventTrack({ name: 'Web_view_USAbanpop' });
  } else {
    eventTrack({ name: 'H5_view_USAbanpop' });
  }
});
</script>
  <style lang="scss">
  .ipWarningModal {
    z-index: 2147483647 !important; // 设置到最大层级
  }
  .ipWarningDialog, .ipWarningDialog-mobile {
    border-radius: 12px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__footer {
      display: none;
    }
    .el-dialog__body {
      border-radius: 12px;
      background: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
      padding: 24px;
      text-align: center;
      .icon-warning {
        width: 48px;
        height: 48px;
        background: url(@/assets/img/common/icon-warning.svg) center/cover no-repeat;
      }
      .icon-close {
        width: 24px;
        height: 24px;
        background: url(@/assets/img/common/icon-close.svg) center/cover no-repeat;
      }
    }
  }
  .ipWarningDialog-mobile {
    .el-dialog__body {
      padding: 20px;
      .icon-warning {
        width: 64px;
        height: 64px;
        background: url(@/assets/img/common/icon-warning-mobile.svg) center/cover no-repeat;
      }
    }
  }
  </style>

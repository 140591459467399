<template>
  <el-dialog
    v-model="dialogVisible"
    class="transfer-dialog no-header"
    width="480px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- 划转引导第二步, fix issue -->
    <div v-if="!assetsStore?.assetGuide?.done" class="guide-area" />
    <div v-if="!assetsStore?.assetGuide?.done" class="guide-overlay" />
    <div v-if="!assetsStore?.assetGuide?.done && assetsStore?.assetGuide?.step == 2">
      <div class="guide-content mt14">
        <span class="triangle" />
        <div class="icon-step-2 mb4" />
        <div class="text-sm-m gray-900 mb4">
          {{ t("资产划转") }}
        </div>
        <div class="text-sm-r gray-700 mb16">
          {{ t("选择划转币种和数量（此划转无费用）") }}
        </div>
        <div class="flex-row items-center ">
          <div class="gray-500 flex1">
            <span class="gray-900">2/</span>4
          </div>
          <div class="flex-row-flex-end flex-row items-center">
            <div class="text-sm-m gray-900 cursor-pointer" @click="onJumpOver">
              {{ $t('跳过') }}
            </div>
            <hk-button class="false-primary-sm text-sm-m base-white h36 ml12" @click="onNextGuide">
              {{ $t('下一步') }}
            </hk-button>
          </div>
        </div>
      </div>
    </div>
    <div class="transfer-content">
      <div class="text-lg-m hk-header-color-2">
        {{ $t('划转') }}
      </div>
      <p class="text-sm-r hk-header-color-1 mt4">
        {{ $t('内部转账不收取手续费') }}
      </p>
      <div class="mt16 flex-row--flex-start">
        <div>
          <p class="text-sm-m hk-header-color-1">
            {{ $t('从') }}
          </p>
          <div
            class="transfer-form mt6 cursor-pointer"
            :class="{'active': isFromDrop}"
            @click.stop="dropEvent('from')"
          >
            <p
              v-if="isHasSub"
              class="sub-title fz12 ellipsis text-xs-r hk-header-color-1"
            >
              {{ transfer.from?.accountName }}
            </p>
            <div class="text-sm-r hk-header-color-2" :class="{'mt2': isHasSub}">
              {{ transfer.from?.accountTypeName }}
            </div>
            <div
              v-if="isFromDrop"
              class="transfer-drop-down left-drop"
              :class="{
                'not-sub': !isHasSub
              }"
              @click.stop
            >
              <el-input
                v-if="isHasSub"
                v-model="accountSearch"
                class="searchInput"
                :placeholder="t('搜索')"
                @input="searchInput"
              >
                <template #prefix>
                  <span class="transfer-icon-search" />
                </template>
              </el-input>
              <div class="flex-row-flex-start">
                <ul
                  v-if="isHasSub"
                  class="sub-account-list"
                >
                  <li
                    v-for="(item, index) in subAccountList"
                    :key="index"
                    class="flex-row-space-between items-center cursor-pointer mr8"
                    :class="{
                      active: item.uid === fromOne.value.uid,
                      disabled: item.readOnly
                    }"
                    @click="fromOneSelect(item)"
                  >
                    <span class="ellipsis text-sm-r">
                      {{ item.accountName }}
                    </span>
                    <em class="select-icon" />
                  </li>
                </ul>
                <ul
                  v-if="isSecondShow"
                  class="main-account-list"
                >
                  <li
                    v-for="(item, index) in fromSecondList"
                    :key="index"
                    class="cursor-pointer text-sm-r"
                    :class="{
                      active: item.accountId === transfer.from?.accountId,
                      disabled: itemDisabled(item,transfer.to),
                      ml8: isHasSub
                    }"
                    @click="fromSelect(item)"
                  >
                    <span>{{ item.accountTypeName }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="icon-transfer ml12 mr12 mt40 cursor-pointer"
          :class="{'disabled': !isCanSwitch}"
          @click="isCanSwitch && switchEvent()"
        />
        <div>
          <p class="text-sm-m hk-header-color-1">
            {{ $t('到') }}
          </p>
          <div
            class="transfer-form mt6 cursor-pointer"
            :class="{'active': isToDrop}"
            @click.stop="dropEvent('to')"
          >
            <p
              v-if="isHasSub"
              class="sub-title fz12 ellipsis text-xs-r hk-header-color-1"
            >
              {{ transfer.to?.accountName }}
            </p>
            <div class="text-sm-r hk-header-color-2" :class="{'mt2': isHasSub}">
              {{ transfer.to?.accountTypeName }}
            </div>
            <div
              v-if="isToDrop"
              class="transfer-drop-down right-drop"
              :class="{
                'not-sub': !isHasSub
              }"
              @click.stop
            >
              <el-input
                v-if="isHasSub"
                v-model="accountSearch"
                class="searchInput"
                @input="searchInput"
              >
                <template #prefix>
                  <span class="transfer-icon-search" />
                </template>
              </el-input>
              <div class="flex-row-flex-start">
                <ul
                  v-if="isHasSub"
                  class="sub-account-list"
                  @click.stop
                >
                  <li
                    v-for="(item, index) in subAccountList"
                    :key="index"
                    class="flex-row-space-between items-center cursor-pointer"
                    :class="{
                      active: item.uid === toOne.value.uid,
                      disabled: item.readOnly
                    }"
                    @click="toOneSelect(item)"
                  >
                    <span class="ellipsis">
                      {{ item.accountName }}
                    </span>
                    <em class="select-icon" />
                  </li>
                </ul>
                <ul
                  v-if="isSecondShow"
                  class="main-account-list"
                >
                  <li
                    v-for="(item, index) in toSecondList"
                    :key="index"
                    class="cursor-pointer"
                    :class="{
                      active: item.accountId === transfer.to.accountId,
                      disabled: itemDisabled(item,transfer.from) || item?.accountType == CONST.ACCOUNT_TYPE.OPT_DISPOSAL,
                      ml8: isHasSub
                    }"
                    @click="toSelect(item)"
                  >
                    {{ item.accountTypeName }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="transfer-error-text pt4">
        {{ errorTips.account }}
      </p>
      <!-- 币种下拉列表 -->
      <p class="text-sm-m hk-header-color-1 mt24">
        {{ $t('币种') }}
      </p>
      <div
        class="transfer-select-box mt6"
        @click.stop
      >
        <div
          class="flex-row-space-between items-center transfer-select cursor-pointer radius8"
          :class="{ 'is-disabled': isCheckingBalance, 'transfer-select--active': isDropBox }"
          @click="dropEvent('token')"
        >
          <el-image
            v-show="!isCheckingBalance && visible"
            style="width: 24px; height: 24px"
            :src="transfer?.token?.iconUrl"
            class="mr12"
          >
            <template #error>
              <div class="error-img-slot">
                {{ transfer?.token?.tokenName?.[0] || '' }}
              </div>
            </template>
          </el-image>
          <div class="flex-row items-center flex1">
            <span v-show="!isCheckingBalance && visible" class="text-sm-m token-name mr8">{{ transfer?.token?.tokenName || '' }}</span>
            <span v-show="!isCheckingBalance && visible" class="text-xs-r token-fullname">{{ transfer?.token?.tokenFullName || '' }}</span>
          </div>
          <i
            :class="{
              'icon-up-triangle': isDropBox,
              'icon-down-triangle': !isDropBox,
            }"
          />
        </div>
        <!-- HKEX-6482 仅展示有余额资产和法币估值 -->
        <div
          v-if="isDropBox"
          class="token-list-content"
          :class="{ 'has-search-token': searchTokenText || filterTokenListWithAmountAndSearchText?.length > 4 }"
        >
          <!-- filterable input -->
          <div v-show="searchTokenText || filterTokenListWithAmountAndSearchText?.length > 4" class="drop-down-list-search-wrapper">
            <el-input
              ref="searchInputNormal"
              v-model="searchTokenText"
              clearable
              class="hk-input input-sm hk-assets-network-search-input"
              :placeholder="$t('搜索')"
            >
              <template #prefix>
                <img src="@/assets/img/hk-svg/search.svg" alt="" width="20" height="20" class="text-gray-500">
              </template>
            </el-input>
          </div>
          <ul class="drop-down-list">
            <!-- list -->
            <li
              v-for="(item, index) in filterTokenListWithAmountAndSearchText"
              :key="item.balanceInfoUpdateHash || index"
              class="flex-row items-center"
              :class="{disabled: tokenDisabled(item), selected: item.tokenId === transfer?.token?.tokenId }"
              @click="$event => setToken($event, item)"
            >
              <el-image
                style="width: 24px; height: 24px"
                :src="item.iconUrl"
                class="mr10"
              >
                <template #error>
                  <div class="error-img-slot">
                    {{ item.tokenFullName?.[0] || '' }}
                  </div>
                </template>
              </el-image>
              <div class="flex1">
                <div class="text-sm-m token-name">
                  {{ item.tokenName }}
                </div>
                <div class="text-xs-r token-fullname">
                  {{ item.tokenFullName }}
                </div>
              </div>
              <div class="text-align-right">
                <div class="text-sm-r token-total">
                  {{ addThousandSeparators(item.balanceInfo?.free || 0) }}
                </div>
                <!-- USD本身不显示估价 -->
                <div v-if="item.tokenName !== 'USD'" class="text-xs-r token-usd-total">
                  ${{ addThousandSeparators(item.balanceInfo?.freeUsdtValue || 0) }}
                </div>
              </div>
            </li>
            <!-- empty -->
            <NoData v-if="!isCheckingBalance && !isCheckingLimit && !filterTokenListWithAmountAndSearchText.length" class="no-data-wrapper" />
          </ul>
        </div>
      </div>

      <div class="mt24 flex-row items-center">
        <span class="text-sm-m hk-header-color-1">
          {{ $t('数量') }}
        </span>

        <span class="flex1">{{ '' }}</span>

        <span v-show="!isCheckingBalance && !isCheckingLimit && visible" class="text-sm-r hk-header-color-1">{{ $t('可用') }} {{ setAsset.availableAssets ? addThousandSeparators(setAsset.availableAssets) : '- -' }} {{ transfer.token?.tokenName }}</span>
        <el-popover
          v-if="hasTransferLimit"
          :width="280"
          popper-class="limit-amount-popper"
          placement="top"
          trigger="hover"
        >
          <template #reference>
            <i v-show="!isCheckingBalance && !isCheckingLimit && visible" class="limit-amount" />
          </template>
          <template #default>
            <div>
              <p class="text-sm-m mb16 popper-title">
                {{ $t('限额说明') }}
              </p>
              <p class="text-sm-r">
                {{ $t('交易账户 %s1 剩余限额*：').replace('%s1', transfer.token?.tokenName) }}
              </p>
              <p class="text-sm-m popper-detail">
                {{ `${riskLimit?.limit ? formatNumberShow(riskLimit?.limit, transfer.token?.tokenId) : '- -'} ${transfer.token?.tokenName}` }}
              </p>
              <p class="text-xs-r">
                {{ $t('*交易账户限额与 KYC 中的个人信息相关，以发起时刻的 USD 价格折算') }}
              </p>
            </div>
          </template>
        </el-popover>
      </div>

      <div class="">
        <el-input
          v-model="transfer.amount"
          class="mt6"
          @input="amountInputEvent"
        >
          <template #suffix>
            <span class="transfer-all-btn cursor-pointer" @click="transferAll">
              {{ $t('全部') }}
            </span>
          </template>
        </el-input>
      </div>
      <p class="transfer-error-text pt4">
        {{ errorTips.amount }}
      </p>
      <!-- <div
        v-if="isShowBbinTips"
        class="mt10 bbin-tips family-gilroy-m fz12 lh18 text-3"
        v-html="bbinTextTips"
      /> -->
      <div class="flex-row items-align mt24">
        <hk-button
          class="transfer-btn cancel-btn false-primary-lg text-mg-m flex1 mr12"
          @click="closed"
        >
          {{ $t('取消') }}
        </hk-button>
        <hk-button
          class="transfer-btn cfm-btn false-primary-lg text-mg-m flex1"
          :disabled="isBtnDisabled"
          :loading="btnSwitch"
          @click="submit"
        >
          {{ $t('确认') }}
        </hk-button>
      </div>
      <span
        class="transfer-dialog-closed cursor-pointer"
        @click="closed"
      />
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { cloneDeep, find, isEqual, orderBy } from 'lodash';
import { reactive, ref, defineProps, computed, defineEmits, watch, type Ref } from 'vue';
import { formatNumberShow } from '@/utils/utils';
import { inputNumReg, addThousandSeparators } from '@/utils/utils';
import { useCommonStore } from '@/store/common';
import { useAssetsStore } from '@/store/assets';
// import { useKycInfoStore } from '@/store/kyc';
import { useUserStore } from '@/store/User';
import { toFixed, lt, toFormat, gt } from '@/utils/number';
import { assetApi } from '@/config/api';
import { toast } from '@/utils/toast';
import CONST from '@/config/const';
import BigNumber from 'bignumber.js';
import { BtnType, useContractStore } from '@/store/contract';

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const props = defineProps({
  token: {
    type: String,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  to: {
    type: String,
    default: ''
  },
  // 体验金使用，还需划转数量
  amount: {
    type: String,
    default: ''
  },
  bbinUrl: {
    type: String,
    default: ''
  },
  // TradeToCustody  交易 -> 存管，
  // CustodyToTrade  存管 -> 交易
  // FiatToTrade     法币 -> 交易
  // TradeToFiat     交易 -> 法币
  // TradeToTrade    交易 -> 交易（主交易账户 子交易账户间划转）
  // CustodyToContract 存管 -> 合约
  // OPT
  // PreFund         法币 -> 预存金
  // FutureToCustody 合约 -> 存管
  // FutureToTrade   合约 -> 交易
  // TradeToFuture   交易 -> 合约
  transferType: {
    type: String,
    default: ''
  },
  banCode: {
    type: Number,
    default: 0
  },
  // 划转完成是否需要跳转到交易页面
  goSpotPage: {
    type: Boolean,
    default: true
  }
});

const transferTypeInner = ref('');

watch(() => props.transferType, () => {
  transferTypeInner.value = props.transferType;
}, { immediate: true });

const emits = defineEmits(['update:visible', 'confirm']);
// store名称
const contractStore = useContractStore();
const assetsStore: any = useAssetsStore();
const common: any = useCommonStore();
const userStore: any = useUserStore();
// const kyc = useKycInfoStore();
// 获取币种
common.getCurrency();
// 币种下拉开关
const isDropBox: any = ref(false);
// 转出账户下拉开关
const isFromDrop: any = ref(false);
// 转入账户下拉开关
const isToDrop: any = ref(false);
const accountSearch: any = ref('');
// 子账户资产
const subAssets:any = ref('0');
// 限额信息
const riskLimit: any = ref(null);
// 按钮开关
const btnSwitch: any = ref(false);
const isSecondShow: any = ref(true);
// from一级
const fromOne: any = reactive({
  value: []
});
const searchTokenText = ref('');
// to一级
const toOne: any = reactive({
  value: []
});
/**
 * from_account_type: '',
 * from_account_id: '',
 * to_account_type: '',
 * to_account_id: '',
 * token_id: '',
 * amount: '',
 */
// 划转提交的数据
const transfer: any = reactive({
  from: {},
  to: {},
  amount: '',
  token: {}
});

const errorTips: any = reactive({
  account: '',
  amount: ''
});
// 判断是否超限
const hasTransferLimit = computed(() => {
  if (riskLimit.value?.limitType == 2 && lt(riskLimit.value?.limit, setAsset.value?.availableAssets)) {
    return true;
  } else if (riskLimit.value?.limitType == 3) {
    // riskLimit.value.limit = '0';
    return true;
  }
  return false;
});

// item是否可点击的逻辑
const itemDisabled = (child: any, other: any) => {
  // from 和 to 不能相同
  if (child.accountId === other.accountId) {
    return true;
  }

  // 主账户的法币账户和资金账户不能相互划转
  if (other.isParent && other.accountType == CONST.ACCOUNT_TYPE.CUSTODY && child.isParent && child.accountType == CONST.ACCOUNT_TYPE.FIAT) {
    return true;
  }

  // 主账户的法币账户和资金账户不能相互划转
  if (other.isParent && other.accountType == CONST.ACCOUNT_TYPE.FIAT && child.isParent && child.accountType ==  CONST.ACCOUNT_TYPE.CUSTODY) {
    return true;
  }

  // 当前item是预存金账户，other只有法币可用  [预存金只能与法币账户转账]
  if (child.accountType == CONST.ACCOUNT_TYPE.OMNIBUS) {
    if (other.accountType == CONST.ACCOUNT_TYPE.FIAT) {
      return false;
    }
    return true;
  }

  // 未开通合约账户
  if (child.accountType === CONST.ACCOUNT_TYPE.FUTURES && !openedContract.value) {
    return true;
  }

  // 当 item 非预存金非法币，other 内则预存金不可用    [item 为法币账户，原逻辑不受影响]  [预存金只能与法币账户转账]
  if (child.accountType !== CONST.ACCOUNT_TYPE.OMNIBUS && child.accountType !== CONST.ACCOUNT_TYPE.FIAT) {
    if (other.accountType == CONST.ACCOUNT_TYPE.OMNIBUS) {
      return true;
    }
    return false;
  }

  return false;
};

const fromSelect = async(item: any) => {
  if (item.accountId === transfer.to.accountId) {
    return;
  }

  if (itemDisabled(item, transfer.to)) {
    return;
  }

  transfer.from = item;
  accountSearch.value = '';
  dropEvent();
  // console.log('fromSelect', transfer.from, transfer.to);
  await sendSubAssets();
};

const toSelect = (item: any) => {
  if (item.accountId === transfer.from?.accountId) {
    return;
  }

  if (itemDisabled(item, transfer.from)) {
    return;
  }

  if (item?.accountType == CONST.ACCOUNT_TYPE.OPT_DISPOSAL) {
    return;
  }

  transfer.to = item;
  accountSearch.value = '';
  dropEvent();
};

const fromOneSelect = (item: any) => {
  if (item.readOnly) {
    return;
  }
  fromOne.value = item;
  isSecondShow.value = true;
};

const toOneSelect = (item: any) => {
  if (item.readOnly) {
    return;
  }
  toOne.value = item;
  isSecondShow.value = true;
};

const searchInput = () => {
  if (accountSearch.value) {
    isSecondShow.value = false;
  } else {
    isSecondShow.value = true;
  }
};
/**
 * 选择全部
 */
const transferAll = () => {
  let amount = setAsset.value.availableAssets.replace(/\,/g, '');
  transfer.amount = inputNumReg(amount, transfer?.token?.depositWithdrawAmtPrecision ?? 8);
};

/**
 * 数量输入框input校验
 */
const amountInputEvent = () => {
  transfer.amount = inputNumReg(transfer.amount,  transfer?.token?.depositWithdrawAmtPrecision ?? 8);
};

// HKEX-6482 请求资产余额
const isCheckingBalance = ref(false);
const fetchHasAmountList = async() => {
  if (isCheckingBalance.value) {
    return;
  }
  try {
    isCheckingBalance.value = true;
    // getHasAmountList 传userId参数查询其他非主账户下的余额
    let balanceList = await assetsStore.getHasAmountList(transfer.from.accountType, transfer.from.uid);

    availableBalanceList.value = balanceList || [];
    // console.log('balanceList?', balanceList);
    // balanceUpdateAt.value = Date.now();
    isCheckingBalance.value = false;
  } catch (e) {
    // console.log('e?', e);
    isCheckingBalance.value = false;
  }
};

/**
 * 设置币种
 *
 * @param item 选择的币种
 */
const setToken = async(clickEvt: any, item: any) => {
  if (tokenDisabled(item)) {
    return;
  }
  transfer.token = item;
  isDropBox.value = false;
  transfer.amount = '';
  // 请求子账户资产
  await sendSubAssets(clickEvt !== null ? item : undefined);

};

/**
 * 检查权限
 * @param item
 */
const tokenDisabled = (item: any) => {
  if (!item) {
    return false;
  }
  let disabled = false;
  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.MAIN && transfer.to.accountType == CONST.ACCOUNT_TYPE.CUSTODY) {
    return !item.final?.transferTradeToCustody;
  }

  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.MAIN && transfer.to.accountType == CONST.ACCOUNT_TYPE.FIAT) {
    return !item.final?.transferTradeToFiat;
  }

  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.CUSTODY && transfer.to.accountType == CONST.ACCOUNT_TYPE.MAIN) {
    return !item.final?.transferCustodyToTrade;
  }

  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.FIAT && transfer.to.accountType == CONST.ACCOUNT_TYPE.MAIN) {
    return !item.final?.transferFiatToTrade;
  }

  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.MAIN && transfer.to.accountType == CONST.ACCOUNT_TYPE.MAIN) {
    return !item.final?.transferTradeToTrade;
  }

  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.OPT) {
    return !item.final?.optTransferOut;
  }

  if (transfer.to.accountType == CONST.ACCOUNT_TYPE.OPT) {
    return !item.final?.optTransferIn;
  }

  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.OPT_DISPOSAL && transfer.to.accountType == CONST.ACCOUNT_TYPE.FIAT) {
    return !item.final?.transferFiatToTrade;
  }
  // 交易-合约
  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.MAIN && transfer.to.accountType == CONST.ACCOUNT_TYPE.FUTURES) {
    return !item.final?.transferTradeToFuture;
  }
  // 合约-交易
  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.FUTURES && transfer.to.accountType == CONST.ACCOUNT_TYPE.MAIN) {
    return !item.final?.transferFutureToTrade;
  }
  // 存管-合约
  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.CUSTODY && transfer.to.accountType == CONST.ACCOUNT_TYPE.FUTURES) {
    return !item.final?.transferCustodyToFuture;
  }
  // 合约-存管
  if (transfer.from.accountType == CONST.ACCOUNT_TYPE.FUTURES && transfer.to.accountType == CONST.ACCOUNT_TYPE.CUSTODY) {
    return !item.final?.transferFutureToCustody;
  }

  return disabled;
};

/**
 * 请求子账户资金
 */
const isCheckingLimit = ref(false);
const sendSubAssets = async(item?: any) => {
  // if (isCheckingLimit.value) {
  //   return;
  // }
  const { token, from, to } = transfer;
  if (!token || !token.tokenId) {
    return;
  }

  if (!from || !from.accountId) {
    return;
  }
  isCheckingLimit.value = true;
  /**
   * 请求资产
   */
  await assetApi.transferAvailable({
    account_type: from.accountType,
    account_id: from.accountId,
    token_id: token.tokenId
  }).then((res: any) => {
    if (token.tokenId !== transfer.token?.tokenId) {
      return;
    }
    nextTick(async() => {
      if (res.success) {
        subAssets.value = !res.data.amount || res.data.amount.indexOf('-') > -1 ? '0' : res.data.amount;
        if (item) {
          // 更新列表中余额值
          // console.log(`fetch amount, same token:>>${token.tokenId === item.tokenId}! value equal:>>${new BigNumber(subAssets.value).isEqualTo(item.balanceInfo?.free)}`);
          if (item.balanceInfo && token.tokenId === item.tokenId && !new BigNumber(subAssets.value).isEqualTo(item.balanceInfo.free)) {
            await fetchHasAmountList();
          }
        }
        // riskLimit.value = res?.data?.riskLimit;
      } else {
        subAssets.value = '';
      }
    });
  });
  await nextTick(async() => {
    const { token, from, to } = transfer;
    // 划转限额
    await assetApi.getTransferLimit({
      to_account_type: to.accountType,
      from_account_type: from.accountType,
      token_id: token.tokenId
    }).then((res: any) => {
      if (res.success) {
        if (res?.data?.limitType == 3) {
          res.data.limit = '0';
        }
        riskLimit.value = res?.data;
      }
    }).finally(() => {
      isCheckingLimit.value = false;
    });
  });
};

/**
 * 关闭下拉
 */
const dropEvent = (type = '') => {
  if (!type) {
    isDropBox.value = false;
    isFromDrop.value = false;
    isToDrop.value = false;

    return;
  }

  if (type === 'from') {
    isFromDrop.value = !isFromDrop.value;
    isDropBox.value = false;
    isToDrop.value = false;
  } else if (type === 'to') {
    isToDrop.value = !isToDrop.value;
    isDropBox.value = false;
    isFromDrop.value = false;
  } else if (type === 'token') {
    // 读取余额过程中不能操作
    if (isCheckingBalance.value) {
      return;
    }
    isDropBox.value = !isDropBox.value;
    isToDrop.value = false;
    isFromDrop.value = false;
  }
};

const closeDrop = () => {
  isDropBox.value = false;
  isFromDrop.value = false;
  isToDrop.value = false;
};
// 是否有子账户
const isHasSub = computed(() => {
  const { subAccount } = userStore;

  if (!subAccount) {
    return false;
  }

  if (subAccount.length === 1) {
    return false;
  }

  return true;
});

// kyc状态判断
// const kycStatus = computed(() => {
//   if (kyc?.kycInfo?.totalAuditStatus == CONST.KycTotalStatusEnum.PASS) {
//     // 已kyclAuditStat
//     return true;
//   } else {
//     // 未kyc
//     return false;
//   }
// });

const isOpenedContract = computed(() => {
  return contractStore.openedContract;
});

const fromSecondList = computed(() => {
  // return fromOne.value.subAccounts
  //   ? fromOne.value.subAccounts.filter((item: any) => item.accountType === CONST.ACCOUNT_TYPE.MAIN) || []
  //   : [];

  return fromOne.value.subAccounts || [];
});
const toSecondList = computed(() => {
  // return toOne.value.subAccounts || [];

  return toOne.value.subAccounts || [];
});

const openedContract = computed(() => {
  return contractStore.openedContract === true;
});

// 账户列表
const subAccountList = computed(() => {
  const { subAccount } = userStore;

  if (!subAccount.length) {
    return [];
  }

  const arr = subAccount.map((item: any) => {
    var accountName = '';
    if (item.isParent) {
      accountName = t('主账户');
    } else {
      accountName = item.accountName.split('@')[0] + '(' + item.subAccounts[0].accountId + ')';
    }
    const obj: any = {
      uid: item.uid,
      accountName: accountName,
      status: item.status,
      isParent: item.isParent
    };
    let accountList = item.subAccounts;
    obj.subAccounts = accountList?.map((it: any) => {
      let accountTypeName = '';

      if (it.accountType === CONST.ACCOUNT_TYPE.MAIN) {
        accountTypeName = t('交易账户');
      } else if (it.accountType === CONST.ACCOUNT_TYPE.CUSTODY) {
        accountTypeName = t('资金账户');
      } else if (it.accountType === CONST.ACCOUNT_TYPE.FIAT) {
        accountTypeName = t('法币账户');
      } else if (it.accountType == CONST.ACCOUNT_TYPE.OPT) {
        accountTypeName = t('经纪账户');
      } else if (it.accountType == CONST.ACCOUNT_TYPE.OMNIBUS) { // 主账户添加预存金账户
        accountTypeName = t('预存金账户');
      } else if (it.accountType == CONST.ACCOUNT_TYPE.OPT_DISPOSAL) {
        accountTypeName = t('处置账户');
      } else if (it.accountType == CONST.ACCOUNT_TYPE.FUTURES) {
        accountTypeName = t('合约账户-futures');
      }
      const itemObj = {
        ...it,
        uid: obj.uid,
        accountName: obj.isParent ? t('主账户') : accountName,
        isParent: obj.isParent,
        readOnly: obj.status === 1,
        accountTypeName
      };

      return itemObj;
    });

    return obj;
  });

  if (accountSearch.value) {
    return arr.filter((item: any) => item.accountName.indexOf(accountSearch.value) > -1);
  }

  return arr;
});

// 弹窗显示状态
const dialogVisible = computed(() => {
  return props.visible;
});

// 处置账户不可调转方向
const isCanSwitch = computed(() => {
  return transfer?.from?.accountType !== 8;
});

/**
 * 转入转出账户转换事件
 */
const switchEvent = async() => {
  const { from, to } = transfer;
  const fromObj: any = JSON.parse(JSON.stringify(fromOne.value));
  const toObj: any = JSON.parse(JSON.stringify(toOne.value));

  transfer.from = JSON.parse(JSON.stringify(to));
  transfer.to = JSON.parse(JSON.stringify(from));
  fromOne.value = toObj;
  toOne.value = fromObj;
  await nextTick(async() => {
    // console.log('switchEvent', transfer.token);
    await sendSubAssets();
  });
  // fromChangeEvent();
  // toChangeEvent();
};

/**
 * 关闭划转
 */
const closed = () => {
  emits('update:visible', false);
  nextTick(() => {
    transfer.from = {};
    transfer.to = {};
    transfer.amount = '';
    transfer.token = {};
    errorTips.account = '';
    errorTips.amount = '';
  });
};

/**
 * 提交
 */
const submit = async() => {
  if (btnSwitch.value) {
    return;
  }

  const obj = {
    token_id: transfer.token?.tokenId,
    amount: transfer.amount,
    from_account_type: transfer.from?.accountType,
    from_account_id: transfer.from?.accountId,
    to_account_type: transfer.to?.accountType,
    to_account_id: transfer.to?.accountId
  };
  btnSwitch.value = true;
  const res: any = await assetApi.assetTransfer(obj);
  if (res.success) {
    btnSwitch.value = false;
    // 关闭弹窗
    toast.success(t('划转成功'));
    assetsStore.getTotalAsset();
    assetsStore.getFutureAsset();
    assetsStore.getSubAccountTotal();

    emits('confirm');

    closed();
  } else {
    btnSwitch.value = false;
    toast.error(res.message);
    closed();
  }
};

// HKEX-6482 根据from的类型查询可用币种余额并显示
declare type BalanceInfo = {
  tokenId: string,
  tokenName: string,
  total: string, // 总值
  free: string, // 可用余额
  usdtValue: string, // 换算成usd值
  freeUsdtValue: string, // 可用余额换算成usd值
  balanceUpdatedAt: number
}

const availableBalanceList: Ref<BalanceInfo[]> = ref([]);
// 切换划转账号类型时，清空搜索
watch(() => [[transfer.from?.accountType, transfer.to?.accountType]], () => {
  searchTokenText.value = '';
});

// 打开弹窗或者修改划转from账户时，刷新余额
watch(() => [transfer.from?.accountId], async(newVal, oldVal) => {
  if (isCheckingBalance.value === true || isEqual(newVal, oldVal)) {
    return;
  }
  if (dialogVisible.value && transfer.from?.accountType) {
    await fetchHasAmountList();
  } else {
    nextTick(() => {
      availableBalanceList.value = [];
    });
  }
}, {
  immediate: true,
  deep: true
});

const getAvailableBalanceInfo = (asset: any): BalanceInfo | undefined => {
  return find((availableBalanceList.value), { tokenId: asset.tokenId }) as BalanceInfo;
};

const spotAssets = ref();
// const futureAssets = ref();
// futureAssets.value = assetsStore.futureAssets;
// 币种列表
const filterTokenList:any = computed(() => {
  // const { spotAssets } = assetsStore;
  // if (transfer.to.accountType === CONST.ACCOUNT_TYPE.FUTURES || transfer.from.accountType === CONST.ACCOUNT_TYPE.FUTURES) {
  //   return futureAssets;
  // }
  // console.log('filterTokenList---000', transfer.to?.accountType, transfer.from?.accountType, spotAssets);

  // 如果是合约账户，产品要求写死USDT
  if (transfer.to?.accountType == CONST.ACCOUNT_TYPE.FUTURES || transfer.from?.accountType == CONST.ACCOUNT_TYPE.FUTURES) {
    return spotAssets.value?.filter((items: any) => {
      return items.tokenId === 'USDT';
    });

    // console.log('test', spotAssets.value);

    // return test;
  }

  // from 或 to 存在法币账户 或 预存金账户， 那币种列表就只能展示法币
  if (transfer.to?.accountType == CONST.ACCOUNT_TYPE.FIAT || transfer.from?.accountType == CONST.ACCOUNT_TYPE.FIAT
    || transfer.to?.accountType == CONST.ACCOUNT_TYPE.OMNIBUS || transfer.from?.accountType == CONST.ACCOUNT_TYPE.OMNIBUS
  ) {
    return spotAssets.value?.filter((items: any) => {
      return items.tokenType === 'REAL_MONEY';
    });
  }

  // from 或 to 存在资金账户 那币种列表就只能展示非法币
  if (transfer.to?.accountType == CONST.ACCOUNT_TYPE.CUSTODY || transfer.from?.accountType == CONST.ACCOUNT_TYPE.CUSTODY) {
    return spotAssets.value?.filter((items: any) => {
      return items.tokenType !== 'REAL_MONEY';
    });
  }

  return spotAssets.value;
});

// 币种列表
// 默认选中第一项
// 如果没有可用余额, 显示全部 按tokenId排序
// 如果有, 筛选列表, 只显示有余额的币种, 且按照法币估值倒序排列
const filterTokenListWithAmount: any = computed(() => {
  let cloneAssets = cloneDeep(filterTokenList.value || []);
  // let sortKey = 'tokenId';
  if (!isCheckingBalance.value && availableBalanceList.value && availableBalanceList.value.length > 0) {
    // sortKey = 'freeUsdtValue';
    cloneAssets = cloneAssets.filter((asset: any) => {
      const assetBalanceInfo = getAvailableBalanceInfo(asset);
      return assetBalanceInfo !== undefined && assetBalanceInfo.free !== '0';
    });
    // 如果全为空，显示全部
    if (cloneAssets.length === 0) {
      cloneAssets = cloneDeep(filterTokenList.value || []);
    }
    cloneAssets.map((asset: any) => {
      const balanceInfo = getAvailableBalanceInfo(asset);
      asset.balanceInfo = balanceInfo;
      asset.balanceInfoUpdateHash = asset.tokenId + ':' + (balanceInfo?.balanceUpdatedAt || '');
      asset.freeUsdtValue = balanceInfo?.freeUsdtValue ? new BigNumber(balanceInfo.freeUsdtValue).toNumber() : 0;
      return asset;
    });
  }
  cloneAssets = orderBy(cloneAssets, ['freeUsdtValue', 'tokenId'], ['desc', 'asc']);
  return cloneAssets;
});
/**
 * 搜索框
 */
const filterTokenListWithAmountAndSearchText = computed(() => {
  const searchText = searchTokenText.value?.trim().toLowerCase();
  return filterTokenListWithAmount.value?.filter((item: any) => item.tokenName?.toLowerCase().includes(searchText) || item.tokenFullName?.toLowerCase().includes(searchText));
});
/**
 * 当列表发生变化时，修改Token下拉列表默认选中值
 */
watch(() => filterTokenListWithAmount.value, (oldVal, newVal) => {
  if (!props.visible || isCheckingBalance.value || !(transfer.from?.accountType && transfer.to?.accountType)) {
    return;
  }
  // 弹窗刚打开时transfer.token可能为空
  if (isEqual(oldVal, newVal) && transfer.token?.tokenId) {
    return;
  }
  if (filterTokenListWithAmount.value?.length > 0) {
    // 1. transfer.token没有值 判定是否显示props.token的币种或者是列表第一个不被禁用的
    // 2. transfer.token有值, 判定是否在更新后的列表中
    // 包含的话不做修改，不包含则默认选中新列表第一个不被禁用的(且按USDT估价倒序排列)
    const defaultPropToken = filterTokenListWithAmount.value.find((v: any) => v.tokenId === props.token && !tokenDisabled(v));
    const firstNotDisabledToken = filterTokenListWithAmount.value.find((v: any) => !tokenDisabled(v));
    // console.log('set token', transfer.token?.tokenId, defaultPropToken, firstNotDisabledToken);
    if (!transfer.token?.tokenId) {
      setToken(null, defaultPropToken || firstNotDisabledToken);
    } else {
      let findInList = filterTokenListWithAmount.value.find((v: any) => v.tokenId === transfer.token?.tokenId && !tokenDisabled(v));
      setToken(null, defaultPropToken || findInList || firstNotDisabledToken);
    }
  }
}, {
  immediate: true,
  deep: true
});

// 设置可用资产
const setAsset = computed(() => {
  const { token } = transfer;

  const obj = {
    availableAssets: '0'
  };

  // 没有选择币种时
  if (!token || !Object.keys(token).length) {

    return obj;
  }

  // 没有转出账户时
  if (!Object.keys(transfer.from).length) {
    return obj;
  }

  obj.availableAssets = subAssets.value;

  return obj;
});
// 提交按钮可用状态
const isBtnDisabled = computed(() => {
  const { from, to, amount, token } = transfer;

  if (!from || !to || !from.accountId || !to.accountId || !token || !token.tokenId || !amount || !Number(amount)) {
    return true;
  }

  if (from.accountId === to.accountId) {
    return true;
  }

  // 划转时必须有一个账户是主账户
  // if (!from.isParent && !to.isParent) {
  //   return true;
  // }

  // 账户status !== 1时不让划转
  if (!from.readOnly && !to.readOnly) {
    return true;
  }

  if (
    !setAsset.value.availableAssets
    || setAsset.value.availableAssets === '0'
    || Number(setAsset.value.availableAssets) < 0
    || lt(setAsset.value.availableAssets, amount)
  ) {
    return true;
  }

  // 目前加判断，合约账户之间不能划转
  // if (to.accountType === CONST.ACCOUNT_TYPE.FUTURES && from.accountType === CONST.ACCOUNT_TYPE.FUTURES) {
  //   return true;
  // }

  return false;
});

// const getAssetList = () => {
//   const { spotAssets, futureAssets } = assetsStore;

//   if (transfer.to.accountType === CONST.ACCOUNT_TYPE.FUTURES || transfer.from.accountType === CONST.ACCOUNT_TYPE.FUTURES) {
//     return futureAssets;
//   }

//   return spotAssets;
// };

// 点击下一步
const onNextGuide = () => {
  closed();
  assetsStore.changeGuide({ step: 3 });
};

// 点击跳转按钮
const onJumpOver = () => {
  closed();
  assetsStore.changeGuide({ done: true });
};

// 合约体验金文案提示
const bbinTextTips = computed(() => {
  const text = t('若想使用合约体验金,请先划转%s1,如有疑问请查看%s2合约体验金使用规则%s3');

  return text.replace('%s1', `<span class="text-link weight-700">${props.amount} ${props.token}</span>`)
    .replace('%s2', `<a class="text-link" href="${props.bbinUrl}" rel="noopener nofollow" target="_blank">`)
    .replace('%s3', '</a>');
});
// 是否显示合约体验金提示
// const isShowBbinTips = computed(() => {
//   if (!props.amount || props.amount === '0') {
//     return false;
//   }

//   // 判断是合约主账户
//   if (transfer.to.accountType !== 3 || !transfer.to.isParent) {
//     return false;
//   }

//   return true;
// });

watch(() => userStore.isLogin, (value: any) => {
  if (value) {
    // 请求子账户列表
    userStore.sendtSubAccount(true);
  }
}, { immediate: true });

watch(() => props.visible, async() => {
  subAssets.value = '0';
  if (props.visible && userStore.isLogin) {
    await userStore.sendtSubAccount();
    nextTick(() => {
      spotAssets.value = assetsStore.spotAssets;
    });

    // console.log('assetsStore', assetsStore.spotAssets);
  }
  // searchTokenText.value = '';
}, {
  immediate: true
});

watch(() => [props.visible, userStore.subAccount, props.token], async(valueArr: any) => {
  const [visible, subAccount, token] = valueArr;
  if (visible) {
    // const assetList: any = getAssetList();

    // if (token) {
    //   [transfer.token] = assetList.filter((item: any) => item.tokenId === token);
    //   if (!transfer.token) {
    //     [transfer.token] = assetList;
    //   }
    // } else {
    //   // '/custody/assetView/'  代表是非资产总览页面
    //   if(route.path.indexOf("/custody/assetView/") > -1) {
    //     [transfer.token] = assetList;
    //   }
    // }

    if (subAccount && subAccount.length) {
      // 判断有传transferType时  交易页面的划转弹框不会传该字段 其余页面会传
      if (transferTypeInner.value) {
        let parentAccount = subAccountList.value.filter((item: any) => item.isParent)[0];
        let formType = 1;
        let toType = 5;
        // let filterAccountType = 5;

        switch (transferTypeInner.value) {
          case 'TradeToCustody':
            formType = 1;
            toType = 5;
            // filterAccountType = 5;
            break;
          case 'CustodyToTrade':
            formType = 5;
            toType = 1;
            // filterAccountType = 5;
            break;
          case 'FiatToTrade':
            formType = 6;
            toType = 1;
            // filterAccountType = 6;
            break;
          case 'TradeToFiat':
            formType = 1;
            toType = 6;
            // filterAccountType = 6;
            break;
          case 'OPT':
            formType = 7;
            toType = 1;
            // filterAccountType = 1;
            break;
          case 'PreFund':
            formType = 6;
            toType = 9;
            // filterAccountType = 6;
            break;

          case 'OptDisposal':
            formType = 8;
            toType = 1;
            // filterAccountType = 1;
            break;
          case 'CustodyToContract':
            formType = 5;
            toType = 3;
            break;
          case 'FutureToCustody':
            formType = 3;
            toType = 5;
            break;
          case 'FutureToTrade':
            formType = 3;
            toType = 1;
            break;
          case 'TradeToFuture':
            formType = 1;
            toType = 3;
            break;
          default:
            break;
        }
        transfer.from = parentAccount.subAccounts.filter((item: any) => item.accountType === formType)[0];
        fromOne.value = parentAccount || {};
        transfer.to = parentAccount.subAccounts.filter((item: any) => item.accountType === toType)[0] || {};
        toOne.value = parentAccount || {};
      } else {
        // 此时正在读取余额 transfer.token可能为空, 只需要判定token是否是法币类型即可
        let checkToken = transfer.token || {};
        if (token && !checkToken?.tokenType) {
          const { spotAssets } = assetsStore;
          checkToken = spotAssets?.find((v: any) => v.tokenId === token);
        }
        if (token) {
          // 当前选中token为法币类型
          if (checkToken?.tokenType === 'REAL_MONEY') {
            // from 为 主交易账户的法币账户
            const [fromAccount] = subAccountList.value[0].subAccounts.filter((item: any) => item.accountType === CONST.ACCOUNT_TYPE.FIAT);
            transfer.from = fromAccount;
          } else {
            // 数字货币类型
            // from 为 主交易账户的资金账户
            const [fromAccount] = subAccountList.value[0].subAccounts.filter((item: any) => item.accountType === CONST.ACCOUNT_TYPE.CUSTODY);
            transfer.from = fromAccount;
          }
          fromOne.value = subAccountList.value[0];
        } else {
          transfer.from = subAccountList.value[0].subAccounts[0];
          fromOne.value = subAccountList.value[0];
        }

        let subAccountId = props.to || userStore.currentSubAccountId;

        if (subAccountId) {
          // 根据to 找到对应的交易账户
          const [toAccount] = subAccountList.value.filter((item: any) => item.uid === subAccountId);
          if (toAccount) {
            transfer.to = toAccount.subAccounts[0] || {};
            toOne.value = toAccount;
          } else {
            transfer.to = subAccountList.value[0] ? subAccountList.value[0].subAccounts[1] : {};
            toOne.value = subAccountList.value[0] ? subAccountList.value[0] : {};
          }

        } else {
          // 取主交易账户
          const [toAccount] = subAccountList.value[0].subAccounts.filter((item: any) => item.accountType === CONST.ACCOUNT_TYPE.MAIN);
          transfer.to = toAccount;
          toOne.value = subAccountList.value[0] ? subAccountList.value[0] : {};

          // 异常情况处理 理论上不存在这种场景
          // 如果 from 和 to accountId

          if (transfer.from?.accountId === transfer.to?.accountId) {
            const [toAccount] = subAccountList.value[0].subAccounts.filter((item: any) => item.accountId !== transfer.from?.accountId);
            transfer.to = toAccount;
            toOne.value = subAccountList.value[0] ? subAccountList.value[0] : {};
          }
        }
      }
    }
  }
  // console.log('[props.visible, userStore.subAccount, props.token]', transfer.from, transfer.to);
  await sendSubAssets();
}, { immediate: true });

document.body.addEventListener('click', closeDrop);

onBeforeUnmount(() => {
  document.body.removeEventListener('click', closeDrop);
});
</script>

<style lang="scss">
.open-contract {
  width: 180px !important;
  margin: 0 auto !important;
}
</style>

<style lang="scss" scoped>
.guide-area {
  position: absolute;
  top: -4px;
  left: -4px;
  width: 488px;
  height: 462px;
  border-radius: 12px;
  border: 1px dashed var(--base-white);
  z-index: -2;
}
.guide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.guide-content {
  width: 320px;
  position: absolute;
  top: 4px;
  right: -338px;
  padding: 12px;
  background: var(--base-white);
  border-radius: 8px;

  .triangle {
    position: absolute;
    top: 28px;
    left: -5px;
    width: 13px;
    height: 13px;
    background-color: var(--base-white);
    border-radius: 2px;
    transform: rotate(135deg);
  }

  .icon-step-2 {
    width: 24px;
    height: 24px;
    background: url(@/assets/img/assets/guide-step-2.svg) center/cover no-repeat;
  }
}
.transfer-content {

  .transfer-dialog-closed {
    position: absolute;
    top: 25px;
    right: 31px;
    width: 24px;
    height: 24px;
    background: url(@/assets/img/icon/icon-close.png) 0 0/24px 24px no-repeat;
  }

  .transfer-all-btn {
    padding: 0 5px 0 8px;
    line-height: 40px;
    height: 40px;
    color: var(--primary-500);
  }

  .assets-text {
    span {
      font-size: 12px;
      line-height: 18px;
      color: var(--text-2);

      em {
        color: var(--text-1);
      }
    }
  }

  .transfer-btn {
    height: 44px;
    box-shadow: none !important;
    &.cancel-btn {
      border: 1px solid var(--gray-300);
      background: var(--base-white);
      color: var(--gray-700);
    }
    &.cfm-btn {
      background: var(--gray-800);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: var(--base-white);
      &:disabled {
        background: var(--gray-300);
      }
    }
  }
  .open-btn {
    font-size: 12px;
    box-shadow: none !important;

    &:hover {
      background-color: var(--base-white);
      border:1px solid var(--gray-300);
      color: #626066;
    }
  }

  .transfer-select-box {
    position: relative;
    .el-image {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .token-name, .token-total {
      color: var(--gray-900);
    }
    .token-fullname {
      color: var(--gray-600);
    }

    .transfer-select {
      height: 44px;
      padding: 0 14px;
      border-radius: 8px;
      border: 1px solid var(--gray-300);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      transition: all 0.1s ease-out;
      &:hover:not(.is-disabled), &:focus:not(.is-disabled) {
        border: 1px solid var(--gray-800);
      }
      &.is-disabled {
        opacity: 0.8;
        background-color: var(--gray-25);
        cursor: not-allowed;
      }
      &--active {
        border: 1px solid var(--gray-800);

        /* Shadow/xs focused 4px gray-100 */
        box-shadow: 0 0 0 4px #f4f4f5, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }

      i {
        width: 20px;
        height: 20px;
        transition: transform 0.3s linear;
        background: url(@/assets/img/hk-spot/arrow-down-filled.svg) center/cover no-repeat;

        &.icon-up-triangle {
          transform: rotate(-180deg);
        }

        &.icon-down-triangle {
          transform: rotate(0deg);
        }
      }
    }

    .token-list-content {
      position: absolute;
      top: 48px;
      left: 0;
      padding: 8px 0;
      width: 432px;
      border-radius: 4px;
      border: 1px solid var(--gray-200, #e4e4e7);
      background-color: var(--bg-popper);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.10);
      box-sizing: border-box;
      z-index: 100;
      &.has-search-token {
        padding-top: 0;
      }
      .drop-down-list-search-wrapper {
        position: relative;
        height: 68px;
        width: 100%;
        // height: 56px;
        // padding: 0 14px 8px;
        margin: 0;
        z-index: 1;
        // background: white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        .el-input__wrapper {
          border-radius: 8px;
          border: 0 solid var(--gray-300, #d1d1d6);
          background: var(--gray-50, #fafafa);

          /* Shadow/xs */
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
          input {
            color: var(--gray-900);
          }
        }
        .el-input__inner::placeholder {
          color: var(--gray-500) !important;
        }
      }
      .drop-down-list {
        max-height: 250px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          padding-right: 4px;
        }
        li {
          padding: 8px 12px;
          &:hover, &.selected {
            background: var(--bg-fill);
          }
          &:hover {
            cursor: pointer;
          }
          .token-fullname, .token-usd-total {
            color: var(--gray-400);
          }
          &.disabled {
            opacity: 0.6;
            .token-fullname,
            .token-usd-total,
            .token-total,
            .token-name {
              color: var(--gray-300) !important;
            }
          }
        }
      }
    }
  }

  .transfer-error-text {
    font-size: 14px;
    line-height: 18px;
    color: var(--error);
  }

  .icon-transfer {
    width: 24px;
    height: 24px;
    background: url(@/assets/img/user/icon_transfer_sub_account.svg) 0 0/24px 24px no-repeat;
  }

  .transfer-form {
    position: relative;
    padding: 15px 15px 13px;
    min-height: 50px;
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    width: 192px;
    box-sizing: border-box;
    &::after {
      content: '';
      position: absolute;
      right: 14px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 20px;
      height: 20px;
      background: url(@/assets/img/hk-spot/arrow-down-filled.svg) center/cover no-repeat;
      transition: transform 0.3s linear;
    }
    &.active {
      &::after {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      border: 1px solid var(--gray-800);
    }

    .sub-title {
      line-height: 18px;
      height: 18px;
      width: 80%;
    }

    .main-title {
      line-height: 24px;
      height: 24px;
    }

    .transfer-drop-down {
      position: absolute;
      top: 74px;
      border-radius: 8px;
      box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
      border: 1px solid var(--gray-200);
      min-width: 192px;
      background: var(--base-white);
      z-index: 1;
      box-sizing: border-box;
      cursor: auto;

      &.not-sub {
        width: 188px;
        top: 54px;
      }

      :deep(.el-input__inner) {
        height: 36px;
      }

      .searchInput {
        height: 36px;
        width: 360px;
        margin: 0 12px;
        margin-top: 12px;
        margin-bottom: 8px;
        border-radius: 8px;

      }

      .transfer-icon-search {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(@/assets/img/icon/icon-search.png) center/20px 20px no-repeat;
      }

      .sub-account-list,
      .main-account-list {
        position: relative;
        padding: 0 12px;
        margin: 8px 0;
        width: 192px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0;
          background: transparent; /* make scrollbar transparent */
        }

        li {
          padding: 10px 8px;
          height: 40px;
          line-height: 20px;
          color: var(--text-1);
          border-radius: 6px;
          span {
            flex: 1;
          }
          .select-icon {
            display: block;
            width: 20px;
            height: 20px;
            background: url(@/assets/img/svg/icon-arrow-r.svg) center/16px 16px no-repeat;
          }

          &:hover {
            background-color: var(--gray-50);
          }

          &.active {
            background-color: var(--gray-50);
            color: var(--primary-500);

            // .select-icon {
            //   display: block;
            // }
          }

          &.disabled {
            color: var(--text-3);
            cursor: not-allowed;

            &.active {
              background-color: transparent;
            }

            &:hover {
              background-color: transparent;
            }
          }
        }
      }

      .sub-account-list {
        &::before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: var(--border-divider);
        }
      }
    }

    .left-drop {
      left: 0;
    }

    .right-drop {
      right: 0;
    }
  }

  .bbin-tips {
    padding: 8px 16px;
    background: var(--bg-fill);

    :deep(.text-link) {
      &:hover {
        color: var(--text-link);
      }
    }
  }
}

:deep(.el-input__wrapper) {
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--hk-transfer-color-3) inset;
  background-color: var(--hk-transfer-color-2);
  &.is-focus {
    box-shadow: 0 0 0 1px var(--hk-transfer-color-1) inset;
  }
}
.limit-amount {
  display: inline-block;
  margin-left: 4px;
  min-width: 16px;
  height: 16px;
  background: url(@/assets/img/hk-spot/icon-popper.svg) center/cover no-repeat;
}

.dark {
  .transfer-content {
    .transfer-all-btn {
      color: var(--primary-400);
    }
    .transfer-dialog-closed {
      background: url(@/assets/img/icon/icon-close.png) 0 0/24px 24px no-repeat;
    }

    .transfer-select-box {
      .transfer-select {
        border: 1px solid var(--gray-900);
        background-color: var(--gray-900);
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        &:hover, &:active {
          border: 1px solid var(--gray-400);
        }

      }
    }
    .open-btn {

      &:hover {
        background-color: var(--gray-800);
        border:1px solid var(--gray-300);
        color: var(--base-white);
      }
    }

    .icon-transfer {
      width: 24px;
      height: 24px;
      background: url(@/assets/img/user/icon_transfer_sub_account-dark.svg) 0 0/24px 24px no-repeat;
    }

    .transfer-form {
      border: 1px solid var(--gray-900);
      background: var(--gray-900);

      &:hover {
        border: 1px solid var(--gray-500);
      }

      .transfer-drop-down {
        border: 1px solid var(--gray-700);
        background: var(--gray-800);
        :deep(.el-input__wrapper) {
          &.is-focus {
            outline: 1px solid var(--gray-500);
            background: var(--gray-900);
            box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 -35px 16px -4px rgba(0, 0, 0, 0.12);
          }
        }

        .transfer-icon-search {
          background: url(@/assets/img/icon/icon-search-dark.svg) center/20px 20px no-repeat;
        }

        .sub-account-list,
        .main-account-list {
          li {
            .select-icon {
              background: url(@/assets/img/svg/icon-arrow-r-dark.svg) center/16px 16px no-repeat;
            }

            &:hover {
              background: rgba(255, 255, 255, 0.06);
            }

            &.active {
              color: var(--primary-400);
              background: transparent;
            }

            &.disabled {
              color: var(--gray-600);
            }
          }
        }

      }

    }
    .transfer-btn {
      &.cancel-btn {
        border: 1px solid var(--gray-500);
        color: var(--gray-200);
        background: transparent;
      }
      &.cfm-btn {
        color: var(--gray-900);
        background: var(--gray-100);
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        &:disabled {
          color: var(--gray-400);
          background: var(--gray-600) !important;
        }
      }
    }
    .transfer-select-box {
      .token-name, .token-total {
        color: var(--gray-25) !important;
      }
      .token-fullname, .token-usd-total {
        color: var(--gray-500) !important;
      }
      .token-list-content {
        border: 1px solid var(--Gray-700, #3f3f46) !important;
        background: var(--Gray-800, #26272b) !important;
        box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
      }
      .drop-down-list {
        li.disabled {
          opacity: 1;
          .token-fullname,
          .token-usd-total,
          .token-total,
          .token-name {
            color: var(--gray-600) !important;
          }
        }
      }

    }
  }
  .limit-amount {
    background: url(@/assets/img/hk-spot/icon-popper-dark.svg) center/cover no-repeat;
  }
}
</style>

<style lang="scss">
.token-list-content {
  // search input
  .hk-assets-network-search-input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 68px;
    padding: 8px 14px;
    margin: 0;
    z-index: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    & .dark {
      background: var(--Gray-800, #26272b) !important;
    }
    .el-input__wrapper {
      border-radius: 8px;
      // border: 0px solid var(--gray-300, #D1D1D6);
      // background: var(--gray-50, #FAFAFA);

      /* Shadow/xs */
      // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      // input {
      // color: var(--gray-900);
      // }
    }
    .el-input__inner::placeholder {
      color: var(--gray-500) !important;
    }
  }
  .no-data-wrapper {
    margin: 18px auto 32px;
  }
}

</style>

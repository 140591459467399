<script setup lang="ts">
import { ref, defineEmits, defineExpose } from 'vue';
import { usePopstate } from '@/hook/usePopstate';
import { i18n } from '@/lang/i18n';
import { gaTag, loadScript } from '@/utils/tools';
import CONST from '@/config/const';
import { isApp } from '@/utils/appBridge';

const emits = defineEmits(['captcha-result', 'captcha-close']);
const popstate = usePopstate();
popstate.stateChange(() => {
  captchaObj.value && captchaObj.value.destroy();
});

// 防止重点
const isShow = ref(false);

const langMap: any = {
  'zh-CN': 'zho',
  'zh-TW': 'zho-tw',
  'en-US': 'eng',
  'tr-TR': 'eng',
  'it-IT': 'eng',
  'vi-VN': 'eng',
  'fr-FR': 'fra',
  'de-DE': 'deu',
  'pt-PT': 'por',
};

const captchaObj = ref();
// const showBox = () => {
//   emits('captcha-result', {});
// };
// showBox();
const showBox = () => {
  // 判断gt4.js是否加载成功
  try {
    if (initGeetest4) {
      initGt4();
    } else {
      loadScript(CONST.gt4Url, () => { initGt4(); });
    }
  } catch (error) {
    loadScript(CONST.gt4Url, () => { initGt4(); });
  }
};

const initGt4 = () => {
  const lang = langMap[i18n.global.locale.value] || 'eng';
  const env = import.meta.env.MODE;

  const captchaId = isApp ? (env === 'production' ? '6eb5840205859624e5a9efd875466bc1' : 'f76b42d13bf1e38b21c0b468d61d616a') : (env === 'production' ? '706fed9ffe790d0c1d42ae097699eb2d' : '626d271d535fe23f69f60c62abb0005c');
  // 吊起极验埋点啊
  // console.log('吊起极验埋点啊吊起极验埋点啊吊起极验埋点啊');
  gaTag('v_geetest');
  // eslint-disable-next-line
  // @ts-ignore
  initGeetest4({
    captchaId: captchaId,
    product: 'bind',
    language: lang
  }, (captcha: any) => {
    captchaObj.value = captcha;

    captcha.onReady(() => {
      // 验证码ready之后才能调用verify方法显示验证码
      if (!isShow.value) {
        captcha.showBox();
        isShow.value = true;
      }
    }).onSuccess(() => {
      isShow.value = false;
      const result = captcha.getValidate();
      if (result) {
        result.captcha_id = captchaId;
      }
      gaTag('a_geetest_succ');
      // console.log(result);
      emits('captcha-result', result);
    }).onFail(() => {
      // 失败埋点
      gaTag('a_geetest_fail');
    }).onError(() => {
      console.log('onErroronError');
      isShow.value = false;
    }).onClose(() => {
      console.log('onCloseonCloseonCloseonClose');
      emits('captcha-close');
      isShow.value = false;
    });
  });
};

defineExpose({ showBox });

</script>

